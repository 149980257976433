import React, {Fragment, useEffect, useState} from 'react';
import {Col, Pagination, Row} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {useAuthDispatch, useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {TeacherList} from "../../components/teacher/TeacherList";
import {getProfile, getTeacherList} from "../../api/axiosAPIs";
import _ from "lodash";
import {T_Admin, T_Viewer} from "../../constants/Permission";
import {updateProfile} from "../../helper/utils";


function TeacherManage(props) {
  let history = useHistory();
  const dispatch = useAuthDispatch()
  const {loading, profile} = useAuthState();
  const [state, setState] = useState({page: 1, rowsPerPage: 10, teacherList: [], total: 0});

  const items = [
    {to: '', label: '教員管理'},
  ]

  const onAdd = (e) => {
    e.preventDefault()
    history.push(PageConstant.TEACHER_MANAGE_ADD)
  }

  const onChange = (page) => {
    fetchTeacherList(getParam(page, state.rowsPerPage))
      .catch(console.error);
  }

  const getParam = (page, rowsPerPage) => {
    return {
      page: page,
      per_page: rowsPerPage,
      school_id: profile.teacher.school_id,
    }
  }

  const fetchTeacherList = async (data) => {
    const response = await getTeacherList(data)
    if (!_.isEmpty(response.data)) {
      if (response.data.data) {
        setState({
          page: data.page,
          rowsPerPage: data.per_page,
          teacherList: response.data.data,
          total: response.data.total,
        })
      }
    }
  }

  const fetchProfile = async () => {
    const response = await getProfile()
    if (!_.isEmpty(response.data)) {
      if (response.data.data) {
        updateProfile(dispatch, response.data.data, profile.remember)
      }
    }
  }

  useEffect(() => {
    fetchProfile()
      .catch(console.error);
    fetchTeacherList(getParam(state.page, state.rowsPerPage))
      .catch(console.error);
  }, []);

  return (
    <Fragment>
      <div className={"pb-12 bg-blue-light"}>
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"rounded-xl bg-white overflow-hidden"}>
            <div className={"flex items-center justify-center py-4 bg-blue"}>
              <span className={"ml-4 text-white text-lg font-bold"}>教員管理</span>
            </div>
            <div className={"px-32 py-16"}>
              <Row>
                <Col span={16}>
                  <div className={"flex items-center h-full"}>
                    <span className={"ml-2 text-light-blue text-base font-bold"}>教員アカウント一覧</span>
                  </div>
                </Col>
                <Col span={8}>
                  <div className={"flex justify-end w-full"}>
                    {
                      profile.permission > T_Viewer && (
                        <button
                          type="submit"
                          className={"flex items-center justify-center btn-secondary-semi-round bg-yellow"}
                          style={{width: 300, height: 55}}
                          disabled={profile.permission < T_Admin}
                          onClick={onAdd}
                        >
                          <span className={"text-black text-base font-bold"}>教員アカウントの新規登録</span>
                        </button>
                      )
                    }
                  </div>
                </Col>
              </Row>
              <div className={"pt-8"}>
                <TeacherList
                  teacherList={state.teacherList}
                  onChange={onChange}
                />
              </div>
              <div className={"py-12 flex items-center justify-center"}>
                <Pagination
                  showSizeChanger={false}
                  current={state.page}
                  pageSize={state.rowsPerPage}
                  total={state.total}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(TeacherManage)