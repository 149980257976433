export const PageConstant = {
  HOME: '/home',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  SIGNUP_VIRTUAL_REGISTER: '/signup_virtual_register',
  SIGNUP_FORM: '/signup_form',
  SIGNUP_FORM_CONFIRM: '/signup_form_confirm',
  FORGET_PASSWORD: '/forget_password',
  FORGET_PASSWORD_EMAIL_SEND: '/forget_password_email_send',
  RESET_PASSWORD: '/reset_password',
  RESET_PASSWORD_FINISH: '/reset_password_finish',
  INQUIRY_FORM: '/inquiry_form',
  INQUIRY_FORM_CONFIRM: '/inquiry_form_confirm',
  INQUIRY_FORM_SEND_FINISH: '/inquiry_form_send_finish',

  COLUMNS: '/columns',
  COLUMN_DETAIL: '/column/detail/~:id',
  INSTRUCTOR_DETAIL: '/instructor/detail/~:id',
  THEME_INQUIRY_SEND_FINISH: '/theme_inquiry_send_finish',
  THEMES: '/themes',
  THEME_DETAIL: '/theme/detail/~:id',
  PROGRAMS: '/programs',
  PROGRAM_GUIDE: '/program_guide',
  SHARED_PROGRAM: '/shared_program',
  SHARED_LESSON: '/shared_lesson',
  PROGRAM_DETAIL: '/program/detail/~:id',
  LESSON_DETAIL: '/lesson/detail/~:id',
  TEASER_PROGRAM: '/teaser/program',
  TEASER_LESSON: '/teaser/lesson',
  CERTIFICATE_PUBLISH: '/program/certificate/~:id',
  TEACHER_PROFILE: '/teacher_profile',
  TEACHER_PROFILE_MAIN_INFO: '/teacher_profile_main_info',
  TEACHER_PROFILE_EMAIL: '/teacher_profile_email',
  TEACHER_PROFILE_EMAIL_SEND: '/teacher_profile_email_send',
  TEACHER_PROFILE_PASSWORD: '/teacher_profile_password',
  TEACHER_MANAGE: '/teacher_manage',
  TEACHER_MANAGE_ADD_SEND: '/teacher_manage_add_send',
  TEACHER_MANAGE_ADD: '/teacher_manage_add',
  TEACHER_MANAGE_EDIT: '/teacher_manage_edit',
  TERMS_OF_USE: '/terms_of_use',
  PRIVACY_POLICY: '/privacy_policy',

  _404: '/*',
}
