import React, {Fragment} from 'react';
import { Link } from 'react-scroll';
import styled from "styled-components";
import {StepTitleItem} from "./StepTitleItem";

const Container = styled.div`
  border: 2px solid #EABD00;
  padding: 1rem 2rem;
`

export const StepTitle = (props) => {
  // console.log(props,'props.titles')
  return (
    <Fragment>
      <Container>
        {
          props.titles.map((item, key) => (
            <Link to={`title_destination_` + (key+1) } smooth={true} duration={800} offset={-120}>
              <StepTitleItem key={key} title={item}/>
            </Link>
          ))
        }
      </Container>
    </Fragment>
  )
}
