import React, {Fragment, useState} from 'react';
import {useHistory} from "react-router-dom";
import {ProgramBox} from "./ProgramBox";
import {PageConstant} from "../../constants/PageConstant";
import {ProgramShare} from "../modal/ProgramShare";
import {addSharedProgramUrl} from "../../api/axiosAPIs";
import _ from "lodash";

export const ProgramItem = (props) => {
  let history = useHistory();
  const [sharedState, setSharedState] = useState({isVisible: false, bCopied: false, key: ''});

  const onItemDetailClick = (e, item) => {
    e.preventDefault()
    history.push(PageConstant.PROGRAM_DETAIL, {data: item})
  }

  const onItemShareClick = (e, item) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('program_id', item.id)
    addSharedProgramUrl(formData).then(response => {
      if (!_.isEmpty(response.data)) {
        if (response.data.key) {
          setSharedState({
            isVisible: true,
            bCopied: false,
            key: response.data.key,
          })
        }
      }
    })
  }

  const getUrl = () => {
    return window.location.host + PageConstant.SHARED_PROGRAM + "/" + sharedState.key
  }

  const onCopyUrl = (url) => {
    navigator.clipboard.writeText(url).catch(console.error)
    setSharedState({isVisible: sharedState.isVisible, bCopied: true, key: sharedState.key})
  }

  return (
    <Fragment>
      <div className={"flex items-center "}>
        <div className={"flex justify-start w-full"}>
          <div className={""}>
            <div className={"mb-2"}>
              <span className={"text-xl font-bold"}>{props.item.program_name}</span>
            </div>
            <div className={"flex items-center"}>
              <ProgramBox title={"開催場所"}/>
              <span className={"text-base ml-2"}>{props.item.venue}</span>
            </div>
          </div>
        </div>
        <div className={"flex justify-end"}>
          <div>
            <div className={"mb-2"}>
              <button
                className={"flex items-center justify-center bg-blue btn-primary-round text-white font-bold text-sm h-40px"}
                style={{width: 190}}
                onClick={(e) => onItemDetailClick(e, props.item)}
              >
                <span>詳細</span>
              </button>
            </div>
            <div>
              <button
                className={"flex items-center justify-center bg-yellow btn-secondary-semi-round text-black font-bold text-sm h-40px"}
                style={{width: 190}}
                onClick={(e) => onItemShareClick(e, props.item)}
              >
                <img alt={""} src={require('../../assets/images/upload.png')}/>
                <span className={"ml-2"}>プログラムの共有</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ProgramShare
        isModalVisible={sharedState.isVisible}
        intl={props.intl}
        bCopied={sharedState.bCopied}
        onCopyUrl={onCopyUrl}
        url={getUrl()}
        size={400}
        handleCancel={() => setSharedState({isVisible: false, bCopied: false, key: sharedState.key})}
      />
    </Fragment>
  )
}
