import React, {Fragment, useEffect, useState} from 'react';
import {Input, Modal} from "antd";

export const UploadFinish = (props) => {
  return (
    <Fragment>
      <Modal
        className={"modal-school"}
        title={null}
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        footer={null}
        centered
        width={675}
      >
        <div className={"px-6 py-4"}>
          <div className={"flex items-center justify-center"}>
            <span className={"text-xl font-bold text-light-blue"}>アップロード完了</span>
          </div>
          <div className={"bg-blue my-4 h-px"}/>
          <div className={"flex items-center justify-center"}>
            <span className={"text-base font-bold text-black"}>修了証のPDFを一括ダウンロードしました</span>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}
