import React, {Fragment} from 'react';
import {ColumnBox} from "../column/ColumnBox";
import {AttachItem} from "../common/AttachItem";


export const StepContentItem = (props) => {
  return (
    <Fragment>
      <div className={"bg-gray-700 h-px"} />
      <div className={"px-4 md:px-6 lg:px-10 py-8"}>
        <div className={"py-8 flex items-start flex-col"}>
          <ColumnBox title={props?.item?.section_date} />
          <div className={"pt-3 text-2xl text-yellow font-bold"}>
            {props.item.section_name}
          </div>
        </div>
        {props?.item && (
          <div
            className="ck-content py-6"
            dangerouslySetInnerHTML={{
              __html: props?.item?.section_content.replace(
                /(?:\r\n|\r|\n)/g,
                "<br>"
              ),
            }}
          ></div>
        )}
        {props?.item?.lesson_section_image_urls && props?.item?.lesson_section_image_urls.length > 0 && (
            <div className={"pt-8"}>
              <AttachItem item={props?.item?.lesson_section_image_urls} />
            </div>
          )
        }
      </div>
    </Fragment>
  );
}
