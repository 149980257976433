import React, {Fragment} from 'react';

export const SpanIconBr = (props) => {
  return (
    <Fragment>
      {
        props.name.split("\n").map((item) => (
          <div className={"flex items-center py-2 text-base"}>
            {
              props.radio && (
                <img className={"mr-3"} alt={""} src={require('../../assets/images/item_radio.png')}/>
              )
            }
            {
              props.check && (
                <img className={"mr-3"} alt={""} src={require('../../assets/images/item_check.png')}/>
              )
            }
            <span>{item}</span>
          </div>
        ))
      }
    </Fragment>
  )
}
