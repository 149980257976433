import React, {Fragment} from 'react';
import {injectIntl} from 'react-intl'
import {useAuthState} from "../../context";
import {isLogined} from "../../helper/utils";


function InquiryFormTitle() {
  const {profile} = useAuthState();

  return (
    <Fragment>
      {
        isLogined(profile) && (
          <div className={"flex items-center justify-center mb-10"}>
            <img alt="" src={require('../../assets/images/slash.png')}/>
            <span className={"text-black text-2xl font-bold mx-8"}>お問い合わせ</span>
            <img alt="" src={require('../../assets/images/slash.png')}/>
          </div>
        )
      }
    </Fragment>
  )
}

export default injectIntl(InquiryFormTitle)