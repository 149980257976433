import React, {Fragment} from 'react';
import {Col, Form, Input, Row} from "antd";
import {FormLabel} from "./FormLabel";

export const FormSchool = (props) => {
  return (
    <Fragment>
      <Row className={"flex-coli md:flex-rowi"}>
        <FormLabel label={props.label} required={props.required} />
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            name={props.name}
            rules={[
              {
                required: true,
                message: props.intl.formatMessage({
                  id: "alert.fieldRequired",
                }),
              },
            ]}
            className={"mb-0i md:mb-24pxi"}
          >
            <Input
              size={"large"}
              placeholder={props.placeholder}
              readOnly
              onClick={props.onHandleClick}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }}>
          <div
            className={
              "pl-3 mt-1 flex items-center justify-end md:justify-start underline cursor-pointer mb-24pxi md:mb-0"
            }
          >
            <span onClick={props.onHandleCancel}>リセット</span>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
