import React, {Fragment, useRef, useState} from 'react';
import {Card, Checkbox, Col, Form, Row} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {FormInput} from "../../components/form/FormInput";
import {FormInquiryTextArea} from "../../components/form/FormInquiryTextArea";
import {FormSchool} from "../../components/form/FormSchool";
import {ModalSchool} from "../../components/modal/ModalSchool";
import {useHistory} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import InquiryFormTitle from "./inquiryFormTitle";
import {isLogined} from "../../helper/utils";
import {INQUIRY_CONTENTS, INQUIRY_CONTENTS_OTHER_KEY} from "../../constants/inquiryContents";


function InquiryForm(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const formRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [initFormValue] = useState(isLogined(profile) ? {
    inquiry_name: profile.name,
    email: profile.email,
    school_name: profile.school_name
  } : {});

  const items = [
    {to: '', label: 'お問い合わせ'},
  ]

  const onChangeInputConfirm = e => {
    setIsConfirm(e)
  }

  const onSelectSchool = (e, school) => {
    setIsModalVisible(false)
    if (formRef.current) {
      formRef.current.setFieldsValue({
        school_name: school.school_name
      })
    }
  }

  const onResetSchool = e => {
    if (formRef.current) {
      formRef.current.setFieldsValue({
        school_name: ""
      })
    }
  }

  const onFinish = (data) => {
    if (isLogined(profile)) {
      if (data.content === INQUIRY_CONTENTS_OTHER_KEY) {
        data.content = data.contentName
      } else {
        data.content = INQUIRY_CONTENTS.find(item => item.key === data.content).title
      }
    }
    history.push(PageConstant.INQUIRY_FORM_CONFIRM, {data: data})
  }

  return (
    <Fragment>
      <div className="pb-12 bg-yellow-light">
        <div className={"container"}>
          <Breadcrumb items={items} isChild={props.isChild} />
          <InquiryFormTitle />
          <div className={"flex items-center justify-center mb-10"}>
            <span className={"blue-color text-lg font-bold"}>
              お問い合わせやご依頼は、お気軽に以下のフォームからご連絡ください。
            </span>
          </div>
          <Card style={{ borderRadius: 20 }} className={"p-0 md:p-30px"}>
            <Form ref={formRef} onFinish={onFinish} initialValues={initFormValue}>
              {/*氏名*/}
              <FormInput
                label={props.intl.formatMessage({ id: "form.item.name" })}
                name="inquiry_name"
                placeholder="氏名"
                intl={props.intl}
                required={true}
                readOnly={isLogined(profile)}
              />
              {/*所属学校*/}
              <FormSchool
                label={props.intl.formatMessage({ id: "form.item.school" })}
                name="school_name"
                placeholder="所属学校を検索する"
                intl={props.intl}
                required={true}
                onHandleClick={() => {
                  if (!isLogined(profile)) {
                    setIsModalVisible(true);
                  }
                }}
                onHandleCancel={() => {
                  if (!isLogined(profile)) {
                    onResetSchool();
                  }
                }}
              />
              {/*メールアドレス*/}
              <FormInput
                label={props.intl.formatMessage({ id: "form.item.email" })}
                name="email"
                placeholder="メールアドレス"
                intl={props.intl}
                required={true}
                readOnly={isLogined(profile)}
              />
              {/*電話番号*/}
              {!isLogined(profile) && (
                <FormInput
                  label={props.intl.formatMessage({ id: "form.item.phone" })}
                  name="phone"
                  placeholder="000-0000-0000"
                  intl={props.intl}
                  required={false}
                  readOnly={false}
                />
              )}
              {/*お問合せ内容*/}
              <FormInquiryTextArea
                label={props.intl.formatMessage({ id: "form.item.inquiry" })}
                name="content"
                placeholder="お問い合わせ内容をご入力ください"
                intl={props.intl}
                required={true}
              />
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 17, offset: 7 }}
              >
                <div className={"flex items-start md:items-center mt-5 mb-10"}>
                  <img alt="" src={require("../../assets/images/require.svg")} />
                  <div className={"ml-3"}>
                    <Checkbox onChange={onChangeInputConfirm}>
                      <span className={"text-sm md:text-base ml-1"}>
                        プライバシーポリシーに同意します
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </Col>
              <Row>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 8, offset: 7 }}
                >
                  <button
                    type="submit"
                    disabled={!isConfirm}
                    className={
                      "flex items-center justify-center btn-secondary-semi-round text-yellow text-lg w-full md:w-315px mb-4 md:mb-0"
                    }
                    style={{ height: 55 }}
                  >
                    <span>入力内容を確認する</span>
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
        <ModalSchool
          isModalVisible={isModalVisible}
          intl={props.intl}
          onSelectSchool={onSelectSchool}
          handleCancel={() => setIsModalVisible(false)}
        />
      </div>
    </Fragment>
  );
}

export default injectIntl(InquiryForm)