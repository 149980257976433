import React, {Fragment} from 'react';

export const Bookmark = (props) => {
  return (
    <Fragment>
      <div className={"flex items-center"}>
        <img alt={""} style={{ marginLeft: -14}} src={require('../../assets/images/bookmark.png')}/>
        <span className={"text-white font-bold text-base"} style={{marginLeft: -250, marginTop: 13}}>{props.title}</span>
      </div>
    </Fragment>
  )
}
