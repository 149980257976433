import React, {Fragment} from 'react';
import {Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {PageConstant} from "../../constants/PageConstant";


function TeacherProfileEmailSend(props) {
  const items = [
    {to: PageConstant.TEACHER_PROFILE, label: '教員情報変更'},
    {to: PageConstant.TEACHER_PROFILE_EMAIL, label: 'メールアドレス変更'},
    {to: '', label: '送信完了'},
  ]

  return (
    <Fragment>
      <div className={"pb-12 bg-blue-light"}>
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"rounded-xl bg-white overflow-hidden"}>
            <Spin spinning={null} size="large">
              <div className={"py-12 px-48"}>
                <div className={"flex items-center justify-center"}>
                  <span className={"text-light-blue text-2xl font-bold"}>新たに登録されたメールアドレスへ</span>
                </div>
                <div className={"flex items-center justify-center"}>
                  <span className={"text-light-blue text-2xl font-bold"}>認証用メールを送信しました</span>
                </div>
                <div className={"bg-blue h-px my-4"}/>
                <div className={"flex items-center my-5 px-12"}>
                  <img alt="" src={require('../../assets/images/email_mark.png')}/>
                  <span className={"ml-6 text-sm"}>
                    受信後1時間以内にメール本文中の認証URLをクリックし、メールアドレスの変更を完了してください。
                  </span>
                </div>
              </div>
            </Spin>
          </div>
        </div>
        </div>
    </Fragment>
  )
}

export default injectIntl(TeacherProfileEmailSend)