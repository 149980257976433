import React, {Fragment} from 'react';
import styled from "styled-components";

const TagContainer = styled.div`
  z-index: 1;
  background-color: #eabd00;
  border-radius: 12.5px;
  display: inline-block;
  padding-right: 0.5rem;
  padding-top: 0.125rem;
  padding: 3px 10px 3px 5px;
  font-
`

const EmptyContainer = styled.div`

`
const TagContainerSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -1.92px;
  color: #1e425f;
`

export const Tag = (props) => {
  return (
    <Fragment>
      <div className={"flex items-center"}>
        {props.item && (
          <Fragment>
            {/* <img alt="" src={require("../../assets/images/bg_tag.png")} /> */}
            <TagContainer className={"h-28px md:h-27px"}>
              <TagContainerSpan className={"text-white whitespace-no-wrap"}>
                {props.item}
              </TagContainerSpan>
            </TagContainer>
          </Fragment>
        )}
        {!props.item && (
          <Fragment>
            <EmptyContainer className={"h-28px md:h-27px"} />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
