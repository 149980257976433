import React, {Fragment} from 'react';
import {useHistory} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";
import styled from "styled-components";
import {getTeacherItem} from "../../api/axiosAPIs";
import _ from "lodash";
import {T_Admin, T_Viewer} from "../../constants/Permission";

const AdminContainer = styled.div`
  display: inline-block;
  border: 2px solid black;
  background-color: #3F75A0;
  text-align: center;
  padding: 5px 10px;
  width: 90px;
`
const ViewerContainer = styled.div`
  display: inline-block;
  border: 2px solid black;
  background-color: #D3E3F0;
  text-align: center;
  padding: 5px 10px;
  width: 90px;
`

export const TeacherItem = (props) => {
  let history = useHistory();
  const onItemClick = (e, item) => {
    e.preventDefault()
    getTeacherItem(item.id).then(response => {
      if (!_.isEmpty(response.data)) {
        if (response.data.data) {
          history.push(PageConstant.TEACHER_MANAGE_EDIT, {data: response.data.data});
        }
      }
    })
  }
  return (
    <Fragment>
      <div className={"flex items-center py-4 px-8 cursor-pointer"} onClick={(e) => onItemClick(e, props.item)}>
        <img alt={""} src={require('../../assets/images/avatar.svg')}/>
        <span className={"ml-4 mr-8 text-black text-base font-bold"} style={{width: 250}}>{props.item.teacher_name}</span>
        <img alt={""} src={require('../../assets/images/email.png')}/>
        <span className={"ml-2 mr-16 text-black text-base"} style={{width: 400}}>{props.item.email}</span>
        {
          props.item.permission === T_Admin && (
            <AdminContainer>
              <span style={{fontSize: 15}} className={"text-white"}>管理者</span>
            </AdminContainer>
          )
        }
        {
          props.item.permission === T_Viewer && (
            <ViewerContainer>
              <span style={{fontSize: 15}} className={"text-black"}>閲覧のみ</span>
            </ViewerContainer>
          )
        }

      </div>
      <div className={"bg-gray-700 h-px"}/>
    </Fragment>
  )
}
