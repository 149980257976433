import React, {Fragment} from 'react';
import styled from "styled-components";

const DateContainer = styled.div`
  display: inline-block;
  border: 2px solid black;
  background-color: #EABD00;
  text-align: center;
  padding: 5px 10px;
`

export const ColumnBox = (props) => {
  return (
    <Fragment>
      <DateContainer>
        <span className={"text-sm md:text-lg text-black"}>{props.title}</span>
      </DateContainer>
    </Fragment>
  );
}
