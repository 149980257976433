import React, {Fragment, useEffect, useState} from 'react';
import InquiryForm from "../../pages/inquiry/inquiryForm";
import {StaticAlert} from "../Alert/StaticAlert";
import {ThemeLayout} from "./ThemeLayout";
import {getMainList} from "../../api/axiosAPIs";
import _ from "lodash";
import {ColumnLayout} from "./ColumnLayout";


export const Main = () => {
  const [state, setState] = useState({imageList: [], themeList: []});
  const initImageList = [
    {id: 0, notification_image_url: require('../../assets/images/column_thumbnail.png')},
    {id: 0, notification_image_url: require('../../assets/images/column_thumbnail.png')},
    {id: 0, notification_image_url: require('../../assets/images/column_thumbnail.png')},
  ]

  const getImageListData = (imageList) => {
    for (let i = 0; i < imageList.length; i++) {
      if (imageList[i].notification_image_url === '') {
        imageList[i].notification_image_url = require('../../assets/images/column_thumbnail.png')
      }
    }

    if (imageList.length === 3) {
      return imageList
    }
    const result = Object.assign([], imageList)
    let index = 0;
    for (let i = 0; i < 3 - imageList.length; i++, index++) {
      result.push(initImageList[index])
    }
    return result
  }

  const fetchTopList = () => {
    getMainList()
      .then(response => {
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            setState({
              imageList: getImageListData(response.data.data.notifications),
              themeList: response.data.data.themes,
            })
          }
        }
      })
  }

  useEffect(() => {
    fetchTopList()
  }, []);

  return (
    <Fragment>
      {
        false && (
          <StaticAlert content={"本登録が完了しました"}/>
        )
      }
      <div className="py-10 px-6 md:px-16">
        <ColumnLayout
          imageList={state.imageList}
        />
      </div>
      <ThemeLayout
        themeList={state.themeList}
      />
      <InquiryForm isChild={true}/>
    </Fragment>
  )
}