import React, {Fragment} from 'react';


export const StepTitleItem = (props) => {
  return (
    <Fragment>
      <div className={"flex items-center py-2"}>
        <div className={"ml-4"}>
          <span className={"text-lg text-black font-bold"}>{props?.title?.section_date}</span><span className={"text-lg text-black font-bold"}>{props?.title?.section_name}</span>
        </div>
      </div>
    </Fragment>
  )
}
