import React, {Fragment} from 'react';
import {Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {PageConstant} from "../../constants/PageConstant";
import {getProfile} from "../../api/axiosAPIs";
import _ from "lodash";
import {useAuthDispatch, useAuthState} from "../../context";
import {useHistory} from "react-router-dom";
import {updateProfile} from "../../helper/utils";


function ResetPasswordFinish(props) {
  let history = useHistory();
  const dispatch = useAuthDispatch()
  const {profile} = useAuthState();
  const items = [
    {to: '', label: 'パスワード再設定完了'},
  ]

  const gotoHome = async () => {
    let response = await getProfile()
    if (!_.isEmpty(response.data)) {
      if (response.data.data) {
        updateProfile(dispatch, response.data.data, profile.remember)
        history.push(PageConstant.HOME)
      }
    }
  }

  return (
    <Fragment>
      <div className={"pb-2 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items} />
          <div className="bg-white mx-auto" style={{ borderRadius: 20 }}>
            <Spin spinning={null} size="large">
              <div className={"py-12 px-0"}>
                <div className={"flex items-center justify-center"}>
                  <span className={"text-light-blue text-2xl font-bold tracking-wider"}>
                    パスワードを再設定しました
                  </span>
                </div>
              </div>
            </Spin>
          </div>
          <div className={"flex items-center justify-center my-10"}>
            <button
              className={
                "flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl w-full md:w-350pxi"
              }
              onClick={gotoHome}
            >
              <span className={"mr-8"}>マイページへ</span>
              <img
                alt=""
                src={require("../../assets/images/circle_arrow_yellow.svg")}
              />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(ResetPasswordFinish)