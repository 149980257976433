import React, {Fragment} from 'react';
import {useHistory} from "react-router-dom";
import styled from "styled-components";

const ImgContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
`
const Avatar = styled.img`
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
`

export const InstructorItem = (props) => {
  return (
    <Fragment>
      <div
        className={
          "relative bg-yellow-light rounded-xl md:rounded-115px md:h-200px"
        }
        style={{ border: "2px solid #EABD00", overflow: "hidden" }}
      >
        <div className={"absolute right-0 md:right-50px"}>
          <div className={"justify-end flex"}>
            <img
              alt=""
              src={require("../../assets/images/symbol_profile.svg")}
              className={"w-4/5 md:w-full"}
            />
          </div>
        </div>
        <ImgContainer className="w-1/3 md:w-150px md:h-150px max-w-36 md:max-w-none max-h-36 md:max-h-none mt-4 md:mt-25px ml-4 md:ml-25px aspect-square">
          <Avatar alt="" src={props.item.instructor_thumbnail_url} />
        </ImgContainer>
        <div
          className={
            "ml-2/5 md:ml-200px mr-8 md:mr-100px mt-4 md:mt-25px mb-4 md:mb-25px"
          }
        >
          <div className={"pb-1 md:pb-2"}>
            <span className={"text-xs text-yellow"}>
              {props.item.instructor_eng_name}
            </span>
          </div>
          <div className={"pb-1 md:pb-2"}>
            <span className={"text-base font-bold text-black"}>
              {"講師：" + props.item.instructor_name}
            </span>
          </div>
          <div className={"pb-1 md:pb-2 h-auto md:h-45px"} style={{ overflow: "hidden" }}>
            <span
              className={"text-xs md:text-sm text-black block"}
              style={{ wordWrap: "break-word" }}
            >
              {props.item.venue}
            </span>
          </div>
          <div className={"pt-1"}>
            <button
              className={
                "flex items-center justify-center bg-blue btn-primary-round text-white text-xs md:text-base w-40 md:w-200px h-8 md:h-40px"
              }
              onClick={(e) => props.onItemClick(e, props.item)}
            >
              <span className={"font-bold"}>プロフィール詳細</span>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
