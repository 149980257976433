import React, {Fragment} from 'react';
import styled from "styled-components";
import {Slide} from "../Slide/Slide";
import {StudentMessage} from "../student/StudentMessage";
import {SpanBr} from "../common/SpanBr";

const InstructorItemContainer = styled.div`
  display: grid;
  padding-top: 1rem;
`

const BlueBoxContainer = styled.div`
  display: inline-block;
  border: 2px solid black;
  background-color: #3F75A0;
  text-align: center;
  padding: 3px 10px;
  color: white;
  min-width: 115px;
`
const YellowBoxContainer = styled.div`
  display: inline-block;
  border: 2px solid black;
  background-color: #EABD00;
  text-align: center;
  padding: 3px 10px;
  color: black;
  min-width: 115px;
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
`
const GridItem = styled.div`
  display: flex;
  justify-content: center;
  width: calc((100vw - 32rem - 3rem) * 0.33333);
`

export const InstructorLayout = (props) => {
  return (
    <Fragment>
      <div className={"py-8 rounded-xl bg-white"}>
        {props.instructor && (
          <Slide
            initialSlide={0}
            imageList={props.instructor.instructor_image_urls}
          />
        )}
        <div className={"px-6 md:px-32 pb-4 md:pb-12 "}>
          <GridContainer>
            {props.instructor.instructor_image_urls &&
              props.instructor.instructor_image_urls
                .slice(0, 3)
                .map((url, key) => (
                  <GridItem key={key}>
                    <img alt="" src={url} />
                  </GridItem>
                ))}
          </GridContainer>
        </div>

        <div className={"px-6 md:px-32"}>
          <div
            className={"flex flex-col md:flex-row items-start md:items-center"}
          >
            <span className={"text-black text-lg md:text-2xl font-bold"}>
              講師：{props.instructor.instructor_name}
            </span>
            <span
              className={
                "text-yellow text-base md:text-lg font-bold ml-0 md:ml-8"
              }
            >
              {props.instructor.instructor_eng_name}
            </span>
          </div>
          <div className={"text-gray-600 text-base md:text-lg pt-0 md:pt-4"}>
            <SpanBr name={props.instructor.venue} />
          </div>
          <InstructorItemContainer
            className={
              "grid-template-instructor-page md:grid-template-instructor-page"
            }
          >
            <div>
              <YellowBoxContainer>
                <span className={"text-sm md:text-lg"}>専門分野</span>
              </YellowBoxContainer>
            </div>
            <div className={"text-black text-base ml-0 md:ml-8 mt-1"}>
              <SpanBr name={props.instructor.spec} />
            </div>
          </InstructorItemContainer>
          <InstructorItemContainer
            className={
              "grid-template-instructor-page md:grid-template-instructor-page"
            }
          >
            <div>
              <BlueBoxContainer>
                <span className={"text-sm md:text-lg"}>出身高校</span>
              </BlueBoxContainer>
            </div>
            <div
              className={"text-black text-sm md:text-base ml-0 md:ml-8 mt-1"}
            >
              <SpanBr name={props.instructor.school} />
            </div>
          </InstructorItemContainer>
          <InstructorItemContainer
            className={
              "grid-template-instructor-page md:grid-template-instructor-page"
            }
          >
            <div>
              <YellowBoxContainer>
                <span className={"text-sm md:text-lg"}>出身大学</span>
              </YellowBoxContainer>
            </div>
            <div
              className={"text-black text-sm md:text-base ml-0 md:ml-8 mt-1"}
            >
              <SpanBr name={props.instructor.university} />
            </div>
          </InstructorItemContainer>
          <InstructorItemContainer
            className={
              "grid-template-instructor-page md:grid-template-instructor-page"
            }
          >
            <div>
              <BlueBoxContainer>
                <span className={"text-sm md:text-lg"}>居住地</span>
              </BlueBoxContainer>
            </div>
            <div className={"text-black text-sm md:text-base ml-0 md:ml-8 mt-1"}>
              <SpanBr name={props.instructor.address} />
            </div>
          </InstructorItemContainer>
          <div className={"py-8"}>
            <SpanBr name={props.instructor.profile} />
          </div>
          <div className={"py-8"}>
            <StudentMessage message={props.instructor.pupil_message} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
