import React, {Fragment, useEffect, useState} from 'react';
import { Element } from 'react-scroll';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {InstructorDetailModal} from "../../components/modal/InstructorDetailModal";
import {Slide} from "../../components/Slide/Slide";
import styled from "styled-components";
import {ThemeBox} from "../../components/theme/ThemeBox";
import {InstructorItem} from "../../components/Instructor/InstructorItem";
import {ArrowItem} from "../../components/common/ArrowItem";
import {BikoItem} from "../../components/common/BikoItem";
import {StepTitle} from "../../components/lesson/StepTitle";
import {StepContentItem} from "../../components/lesson/StepContentItem";
import {getInstructorItem, getLessonItem} from "../../api/axiosAPIs";
import _ from "lodash";
import {GoogleSheet} from "../../components/common/GoogleSheet";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
`
const GridItem = styled.div`
  display: flex;
  justify-content: center;
  width: calc((100vw - 32rem - 3rem) * 0.33333);
`

function LessonDetail(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const [modalData, setModalData] = useState({isVisible: false, instructor: {}});
  const [lessonData, setLessonData] = useState({});
  const [steps, setSteps] = useState([]);
  const {data, item} = useLocation().state
  const items = [
    {to: PageConstant.PROGRAMS, label: 'プログラム管理'},
    {to: '', label: data.program_name},
    {to: '', label: item.lesson_name},
  ]
  let lesson_image_urls = []

  const onInstructorItemClick = (e, item) => {
    getInstructorItem(item.id).then(response => {
      if (!_.isEmpty(response.data)) {
        if (response.data.data) {
          setModalData({
            isVisible: true,
            instructor: response.data.data
          })
        }
      }
    })
  }

  const fetchLessonItem = (lessonId) => {
    getLessonItem(lessonId)
      .then(response => {
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            const data = response.data.data
            setLessonData(data)

            const stepData = []
            for (let i = 0; i < data.section_count; i++) {
              stepData.push({
                section_name: data['section_name' + (i + 1)],
                section_date: data['section_date' + (i + 1)],
                section_content: data['section_content' + (i + 1)],
                lesson_section_image_urls: data['lesson_section_image_urls' + (i + 1)],
              })
            }
            setSteps(stepData)
          }
        }
      })
  }

  useEffect(() => {
    fetchLessonItem(item.id)
    lesson_image_urls = item.lesson_image_urls.slice(0, 3)
    if (lesson_image_urls.length < 3) {
      for (let i = 0; i < 3 - lesson_image_urls.length; i++) {
        lesson_image_urls.push("")
      }
    }

  }, []);

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"flex justify-center items-center py-8"}>
            <span className={"font-bold text-black text-3xl"}>{item.lesson_name}</span>
          </div>
          <div className={"rounded-xl bg-white"}>
            <Slide
              initialSlide={0}
              imageList={item.lesson_image_urls}
            />
            <div className={"px-32 pb-12"}>
              <GridContainer>
                {
                  lesson_image_urls.map((url, key) => (
                    <GridItem key={key}>
                      {
                        url !== '' && (
                          <img alt="" src={url}/>
                        )
                      }
                    </GridItem>
                  ))
                }
              </GridContainer>
            </div>
            <div className={"px-48"}>
              <ThemeBox title={'ジャンル：' + (lessonData.genre_name ? lessonData.genre_name : '')}/>
              <div className={"pt-12"}>
                <span className={"text-base leading-8"}>
                  {
                    lessonData.content && (
                      <div className="ck-content"
                          dangerouslySetInnerHTML={{__html: lessonData.content.replace(/(?:\r\n|\r|\n)/g, '<br>')}}>
                      </div>
                    )
                  }
                </span>
                {
                  lessonData.instructors && (
                    lessonData.instructors.map((instructor, key) => (
                      <div key={key} className={"py-8"}>
                        <InstructorItem
                          item={instructor}
                          onItemClick={onInstructorItemClick}
                        />
                      </div>
                    ))
                  )
                }
              </div>
            </div>

            {/*授業情報*/}
            <div className={"px-32 py-12"}>
              <ArrowItem title={"授業情報"}/>
              <div className={"px-16"}>
                {
                  lessonData.info && (
                    <div className="ck-content py-6"
                        dangerouslySetInnerHTML={{__html: lessonData.info.replace(/(?:\r\n|\r|\n)/g, '<br>')}}>
                    </div>
                  )
                }
                {
                  lessonData.remark && (
                    <div className={"py-6"}>
                      <BikoItem content={lessonData.remark}/>
                    </div>
                  )
                }
              </div>
            </div>

            {/*流れについて*/}
            <div className={"px-32"}>
              <ArrowItem title={"流れについて"}/>
              <div className={"px-16 py-12"}>
                <GoogleSheet url={data.flow_comment}/>
              </div>
            </div>

            {/*授業ステップ*/}
            <div className={"flex items-center justify-center bg-blue py-4"}>
              <span className={"text-2xl text-white font-bold"}>授業ステップ</span>
            </div>
            <div className={"px-32"}>
              <div className={"py-8 px-10"}>
                <StepTitle titles={steps.map(item => item)}/>
              </div>
              {
                steps.map((item, key) => (
                  <Element name={`title_destination_` + (key+1) } className="element">
                    <StepContentItem
                      key={key}
                      item={item}
                    />
                  </Element>
                ))
              }
            </div>
          </div>
          <div className={"flex justify-center pt-12"}>
            <button
              className={"flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl"}
              style={{width: 300}}
              onClick={history.goBack}
            >
              <span>戻る</span>
              <img alt="" className={"absolute "} style={{marginLeft: 140}}
                  src={require('../../assets/images/circle_arrow_yellow.svg')}/>
            </button>
          </div>
        </div>
      </div>
      <InstructorDetailModal
        isModalVisible={modalData.isVisible}
        intl={props.intl}
        instructor={modalData.instructor}
        handleCancel={() => setModalData({isVisible: false, instructor: {}})}
      />
    </Fragment>
  )
}

export default injectIntl(LessonDetail)