import React, { Fragment, useEffect, useState } from "react";
import { Pagination } from "antd";
import { Breadcrumb } from "../../components/breadcrumb/Breadcrumb";
import { injectIntl } from "react-intl";
import { useAuthState } from "../../context";
import styled from "styled-components";
import { ColumnItem } from "../../components/column/ColumnItem";
import { getNotificationList } from "../../api/axiosAPIs";
import _ from "lodash";

const GridContainer = styled.div`
  display: grid;
  grid-row-gap: 46px;
  grid-column-gap: 20px;
`;

function Columns(props) {
  const { loading, profile } = useAuthState();
  const [state, setState] = useState({
    page: 1,
    rowsPerPage: 10,
    columnList: [],
    total: 0,
  });

  const items = [{ to: "", label: "探究関連情報" }];

  const onChange = (page, pageSize) => {
    fetchColumnList(page, pageSize);
  };

  const getParam = (page, rowsPerPage) => {
    return {
      page: page,
      per_page: rowsPerPage,
    };
  };

  const fetchColumnList = (page, rowsPerPage) => {
    getNotificationList(getParam(page, rowsPerPage)).then((response) => {
      if (!_.isEmpty(response.data)) {
        if (response.data.data) {
          setState({
            page: page,
            rowsPerPage: rowsPerPage,
            columnList: response.data.data,
            total: response.data.total,
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchColumnList(state.page, state.rowsPerPage);
  }, []);

  return (
    <Fragment>
      <div className={"pb-0 md:pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items} />
          <div className={"flex justify-center pb-15"}>
            <img alt="" src={require("../../assets/images/mark_column_b.svg")} />
          </div>
          <GridContainer className="gap-columns md:gap-columns md:grid-template-columns-page">
            {state.columnList.map((item, key) => (
              <div key={key} className="w-calc_grid_ext md:w-calc_grid_ext">
                <ColumnItem item={item} />
              </div>
            ))}
          </GridContainer>
          <div className={"py-12 flex items-center justify-center"}>
            <Pagination
              showSizeChanger={false}
              current={state.page}
              pageSize={state.rowsPerPage}
              total={state.total}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(Columns);
