import React, {Fragment} from 'react';
import {Card, Col, Divider, Row} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {FormInputValue} from "../../components/form/FormInputValue";
import {useHistory, useLocation} from "react-router-dom";
import {useAuthDispatch, useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {FormInputPasswordValue} from "../../components/form/FormInputPasswordValue";
import {getProfile, signupConfirm} from "../../api/axiosAPIs";
import _ from "lodash";
import {updateProfile} from "../../helper/utils";
import {FormTextAreaValue} from "../../components/form/FormTextAreaValue";


function SignupFormConfirm(props) {
  let history = useHistory();
  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();
  const {data, profile} = useLocation().state;

  const items = [
    { to: PageConstant.SIGNUP_FORM, label: "新規会員登録" },
    { to: "", label: "本登録内容確認" },
  ];

  const onSend = async () => {
    let formData = new FormData();
    if (profile.pattern === "a") {
      formData.append("teacher_name", data.teacher_name);
      formData.append("prefecture_id", data.pid);
      formData.append("contracted_school_id", data.csid);
      formData.append("school_id", data.school_id);
    } else if (profile.pattern === "c") {
      formData.append("teacher_name", profile.name);
    }
    formData.append("class_name", data.class);
    formData.append("subject_name", data.subject);
    formData.append("login_password", data.password);
    formData.append("name", profile.name);
    formData.append("email", profile.email);
    formData.append("pattern", profile.pattern);
    formData.append("permission", profile.permission);
    try {
      let response = await signupConfirm(formData);
      if (response.status === 200) {
        response = await getProfile();
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            updateProfile(dispatch, response.data.data, profile.remember);
            history.push(PageConstant.HOME);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className={"pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items} />
          <div className={"flex items-center justify-center mb-6 md:mb-10"}>
            <span className={"blue-color text-lg font-bold"}>本登録</span>
          </div>
          <Card style={{ borderRadius: 20 }} className={"p-0i md:p-30pxi"}>
            <div className={"flex items-center mb-10"}>
              <span className={"text-light-blue text-lg font-bold"}>
                入力内容の確認
              </span>
            </div>
            {profile.pattern === "a" && (
              <Fragment>
                {/*教員氏名*/}
                <FormInputValue
                  label={"教員氏名"}
                  value={data.teacher_name}
                  intl={props.intl}
                  required={true}
                />
                <Divider className={"mt-3i md:mt-24pxi"} />
                {/*所属学校*/}
                <FormInputValue
                  label={props.intl.formatMessage({ id: "form.item.school" })}
                  value={data.school}
                  intl={props.intl}
                  required={true}
                />
                <Divider className={"mt-3i md:mt-24pxi"} />
              </Fragment>
            )}
            {/*担当科目*/}
            <FormTextAreaValue
              label={props.intl.formatMessage({
                id: "form.item.teacher.subject",
              })}
              value={data.subject}
              intl={props.intl}
              required={false}
            />
            <Divider className={"mt-3i md:mt-24pxi"} />
            {/*担当クラス*/}
            {/*<FormInputValue*/}
            {/*  label={props.intl.formatMessage({id: 'form.item.teacher.class'})}*/}
            {/*  value={data.class}*/}
            {/*  intl={props.intl}*/}
            {/*  required={false}*/}
            {/*/>*/}
            {/*<Divider/>*/}
            {/*初期パスワード*/}
            <FormInputPasswordValue
              label={props.intl.formatMessage({ id: "form.item.init.password" })}
              value={data.password}
              intl={props.intl}
              required={true}
            />
            <Row>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 8, offset: 8 }}
              >
                <button
                  className={
                    "mt-12 bg-yellow flex items-center justify-center btn-secondary-semi-round text-base w-full md:w-315px mb-4 md:mb-0"
                  }
                  style={{ height: 55 }}
                  onClick={onSend}
                >
                  <span>本登録</span>
                </button>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(SignupFormConfirm)