import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../context";
import {ThemeList} from "../../components/theme/ThemeList";
import {Input, Pagination, Select} from "antd";
import {getThemeList, getProgramTypesList} from "../../api/axiosAPIs";
import _ from "lodash";

const {Option} = Select;


function Themes(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const [keyword, setKeyword] = useState('');
  const [programTypes, setProgramTypes] = useState([]);
  const [selectedProgramTypes, setSelectedProgramTypes] = useState([]);
  const [genreId, setGenreId] = useState('');
  const [state, setState] = useState({page: 1, rowsPerPage: 15, themeList: [], genreList: [], total: 0});
  const location = useLocation()
  const locationCache = useRef(false)

  const items = [
    {to: '', label: 'MIETAN教材'},
  ]

  const onChange = (page, rowsPerPage) => {
    fetchThemeList(page, rowsPerPage)
  }

  const getParam = (page, rowsPerPage, keyword, genreId, selectedProgramTypes) => {
    return {
      page: page,
      per_page: rowsPerPage,
      keyword: keyword,
      program_type: selectedProgramTypes,
      genre_id: genreId,
    }
  }

  const onSearch = () => {
    locationCache.current = (location.state) ? location.state.cache : Math.random().toString()
    fetchThemeList(state.page, state.rowsPerPage, keyword, genreId, selectedProgramTypes)
  }

  const fetchThemeList = (page, rowsPerPage, keyword, genreId, selectedProgramTypes) => {
    getThemeList(getParam(page, rowsPerPage, keyword, genreId, selectedProgramTypes))
      .then(response => {
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            setState({
              page: page,
              rowsPerPage: rowsPerPage,
              total: response.data.total,
              themeList: response.data.data,
              genreList: response.data.genre,
            })
          }
        }
      })
  }

  useEffect(() => {
    fetchThemeList(state.page, state.rowsPerPage, keyword, genreId, selectedProgramTypes)
  }, []);

  useEffect(() => {
    const cache = (location.state) ? location.state.cache : ''
    if (locationCache.current && locationCache.current !== cache) {
      locationCache.current = false
      setKeyword("")
      setGenreId("")
      setSelectedProgramTypes([])
      fetchThemeList(1, state.rowsPerPage, "", "")
    }
  }, [props]);

  // get prgrams types
  useEffect(() => {
    const fetchProgramTypes = async () => {
      const response = await getProgramTypesList();
      const programtype = response.data.data;
      setProgramTypes(programtype);
    };

    fetchProgramTypes();
  }, []);

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedProgramTypes([...selectedProgramTypes, value]);
    } else {
      setSelectedProgramTypes(selectedProgramTypes.filter(item => item !== value));
    }
  };

  // console.log(selectedProgramTypes,'selectedProgramTypesselectedProgramTypes');

  return (
    <Fragment>
      <div className="pb-2 bg-home-theme">
        <div className={"container"}>
          <Breadcrumb items={items} />
          <div className={"flex justify-center pb-5"}>
            <img alt="" src={require("../../assets/images/mark_theme.svg")} />
          </div>
        </div>
      </div>
      <div className={"bg-yellow py-8"}>
        <div className={"container"}>
          <div className={ "flex items-start md:items-center flex-col md:flex-row mb-8 mr-24 w-full md:w-auto" }>
            <span
              className={
                "text-base font-bold text-black whitespace-no-wrap mr-8 mb-1 md:mb-0"
              }
            >
              キーワード
            </span>
            <Input
              size={"large"}
              placeholder={"キーワードを入力"}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className={"w-fulli md:w-2/5i"}
            />
            <span
              className={
                "text-base font-bold text-black whitespace-no-wrap ml-0 md:ml-16 mr-8 mt-4 md:mt-0 mb-1 md:mb-0"
              }
            >
              ジャンル
            </span>
            <Select
              size={"large"}
              className={"mr-2 w-fulli md:w-300pxi rounded"}
              placeholder={"ジャンルを選択"}
              value={genreId}
              onChange={(value) => {
                setGenreId(value);
              }}
            >
              <Option value={""}> </Option>
              {state.genreList.map(function (item, index) {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className={"flex justify-left"}>
            <div
              className={
                "flex items-start md:items-center flex-col md:flex-row mb-8 mr-24 w-full md:w-auto"
              }
            >
              <span
                className={
                  "text-base font-bold text-black whitespace-no-wrap mr-20 mb-1 md:mb-0"
                }
              >
                形式
              </span>
              <div className={"flex check-box-group"}>
                { programTypes && programTypes.map(({ id, name }) => (  
                  <div className={"flex items-center formate-check"} key={id}>
                    <input className={'checkbox-round'} type="checkbox" id={`checkbox_${id}`} name="program_type" value={id} onChange={handleCheckboxChange} />
                    <label className={"text-base font-bold text-black whitespace-no-wrap mr-8 mb-1 md:mb-0"} id={`checkbox_${id}`}>{name}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={"flex justify-center"}>
            <button
              className={
                "flex items-center justify-center bg-blue btn-primary-semi-round text-white text-xl w-full md:w-315px"
              }
              style={{ height: 55 }}
              onClick={onSearch}
            >
              <span className={"mr-3 text-lg font-bold"}>検索</span>
              <img
                alt=""
                src={require("../../assets/images/right_symbol_search.png")}
              />
            </button>
          </div>
        </div>
      </div>
      <div className={"pt-12 pb-0 md:py-12 bg-home-theme"}>
        <div className={"container"}>
          <div>
            <span className={"text-base text-black font-bold"}>
              MIETAN教材一覧
            </span>
          </div>
          <div className={"mt-8"}>
            <ThemeList themeList={state.themeList} />
            <div className={"py-12 flex items-center justify-center"}>
              <Pagination
                showSizeChanger={false}
                current={state.page}
                pageSize={state.rowsPerPage}
                total={state.total}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(Themes)