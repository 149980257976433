import React, {Fragment, useEffect} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useAuthState} from "../../context";


function ProgramGuide(props) {
  const {loading, profile} = useAuthState();

  const items = [
    {to: '', label: 'プログラム管理'},
  ]

  useEffect(() => {
  }, []);

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items}/>
        <div className={"flex justify-center pb-5"}>
          <img alt="" src={require('../../assets/images/mark_manage.svg')}/>
        </div>
        <div className={"rounded-xl bg-white overflow-hidden"}>
          <div className={"bg-blue py-5"}>
            <span className={"flex justify-center text-white font-bold text-lg"}>プログラム一覧</span>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(ProgramGuide)