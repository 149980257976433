import React, {Fragment} from 'react';
import {SlideCenterMode} from "../Slide/SlideCenterMode";
import {Link, useHistory} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";

export const ColumnLayout = (props) => {
  let history = useHistory();
  const imageList = Object.assign([], props.imageList)
  for (let i = 0; i < props.imageList.length; i++) {
    imageList.push(props.imageList[i])
  }

  const onItemClick = (e, item) => {
    e.preventDefault()
    history.push(PageConstant.COLUMN_DETAIL, {data: item})
  }

  return (
    <Fragment>
      <div className="container bg-yellow " style={{borderRadius: 20, overflow: 'hidden'}}>
        <img alt="" src={require('../../assets/images/symbol_column.svg')}
             className={"absolute"}/>
        <div className={"py-10 "}>
          <div className={"flex justify-center pb-5"}>
            <img alt="" src={require('../../assets/images/mark_column.svg')}/>
          </div>
          <SlideCenterMode
            initialSlide={0}
            width={400}
            height={220}
            imageList={imageList}
            onItemClick={onItemClick}
          />
          <div className={"flex justify-center mt-16"}>
            <Link to={PageConstant.COLUMNS}
                  className={"flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl"}
                  style={{width: 350}}>
              <span className={"mr-8"}>コラム一覧</span>
              <img alt="" src={require('../../assets/images/circle_arrow_yellow.svg')}/>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
