import React, {Fragment, useRef} from 'react';
import {Col, Divider, Form, Row} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {FormInputPassword} from "../../components/form/FormInputPassword";
import {FormInputConfirmPassword} from "../../components/form/FormInputConfirmPassword";
import {changeTeacherPassword} from "../../api/axiosAPIs";


function TeacherProfilePassword(props) {
  let history = useHistory();
  const formRef = useRef();
  const {loading, profile} = useAuthState();

  const items = [
    {to: PageConstant.TEACHER_PROFILE, label: '教員情報変更'},
    {to: '', label: 'パスワード変更'},
  ]

  const onFinish = (data) => {
    let formData = new FormData()
    formData.append('id', profile.teacher.id)
    formData.append('old_password', data.old_password)
    formData.append('password', data.password)
    changeTeacherPassword(formData).then(response => {
      if (response.status === 200) {
        history.push(PageConstant.TEACHER_PROFILE)
      }
    })
  }

  return (
    <Fragment>
      <div className={"pb-12 bg-blue-light"}>
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"rounded-xl bg-white overflow-hidden"}>
            <div className={"flex items-center justify-center py-4 bg-profile-yellow"}>
              <span className={"ml-4 text-black text-lg font-bold"}>教員情報変更</span>
            </div>
            <div className={"px-32 py-16"}>
              <Form
                ref={formRef}
                onFinish={onFinish}
              >
                <Row>
                  <Col span={6} offset={2}>
                    <div style={{marginLeft: 45}}>
                      <span className={"ml-2 text-light-blue text-base font-bold"}>パスワード変更</span>
                    </div>
                  </Col>
                  <Col span={12}/>
                </Row>
                <Divider/>
                {/*現在のパスワード*/}
                <FormInputPassword
                  label={"現在のパスワード"}
                  name="old_password"
                  placeholder="パスワードを入力"
                  intl={props.intl}
                  required={true}
                />
                <Divider/>
                {/*新しいパスワード*/}
                <FormInputPassword
                  label={"新しいパスワード"}
                  name="password"
                  placeholder="パスワードを入力"
                  intl={props.intl}
                  required={true}
                />
                <Divider/>
                {/*新しいパスワード（確認）*/}
                <FormInputConfirmPassword
                  label={"新しいパスワード（確認）"}
                  name="confirmPassword"
                  dependency={"password"}
                  placeholder="パスワードを入力"
                  intl={props.intl}
                  required={false}
                />
                <Divider/>
                <Row>
                  <Col span={8} offset={8}>
                    <button
                      type="submit"
                      className={"mt-6 flex items-center justify-center btn-primary-semi-round bg-blue"}
                      style={{width: 315, height: 55}}
                    >
                      <span className={"text-white text-base font-bold"}>パスワードを変更する</span>
                    </button>
                  </Col>
                </Row>

              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(TeacherProfilePassword)