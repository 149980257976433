import React, {Fragment} from 'react';
import {Card, Col, Divider, Row} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {FormInputValue} from "../../components/form/FormInputValue";
import {useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {FormTextAreaValue} from "../../components/form/FormTextAreaValue";
import InquiryFormTitle from "./inquiryFormTitle";
import {isLogined} from "../../helper/utils";
import {sendInquiry} from "../../api/axiosAPIs";


function InquiryFormConfirm(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const data = useLocation().state.data;

  const items = [
    {to: PageConstant.INQUIRY_FORM, label: 'お問い合わせ'},
    {to: '', label: '入力内容確認'},
  ]

  const onSend = () => {
    sendInquiry(data).then(response => {
      if (response.status === 200) {
        history.push(PageConstant.INQUIRY_FORM_SEND_FINISH)
      }
    })
  }

  return (
    <Fragment>
      <div className={"pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <InquiryFormTitle/>
          <Card style={{borderRadius: 20, padding: 30}}>
            <div className={"flex items-center mb-10"}>
              <span className={"text-light-blue text-lg font-bold"}>入力内容の確認</span>
            </div>
            {/*氏名*/}
            <FormInputValue
              label={props.intl.formatMessage({id: 'form.item.name'})}
              value={data.inquiry_name}
              intl={props.intl}
              required={true}
            />
            <Divider/>
            {/*所属学校*/}
            <FormInputValue
              label={props.intl.formatMessage({id: 'form.item.school'})}
              value={data.school_name}
              intl={props.intl}
              required={true}
            />
            <Divider/>
            {/*メールアドレス*/}
            <FormInputValue
              label={props.intl.formatMessage({id: 'form.item.email'})}
              value={data.email}
              intl={props.intl}
              required={true}
            />
            <Divider/>
            {/*電話番号*/}
            {
              !isLogined(profile) && (
                <Fragment>
                  <FormInputValue
                    label={props.intl.formatMessage({id: 'form.item.phone'})}
                    value={data.phone}
                    intl={props.intl}
                    required={false}
                  />
                  <Divider/>
                </Fragment>
              )
            }
            {/*お問合せ内容*/}
            <FormTextAreaValue
              label={props.intl.formatMessage({id: 'form.item.inquiry'})}
              value={data.content}
              intl={props.intl}
              required={true}
            />
            <Row>
              <Col span={8} offset={8}>
                <button
                  className={"mt-12 bg-yellow flex items-center justify-center btn-secondary-semi-round text-base"}
                  style={{width: 315, height: 55}}
                  onClick={onSend}
                >
                  <span>送信する</span>
                </button>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(InquiryFormConfirm)