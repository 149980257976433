import React, {Fragment} from 'react';
import {Form, Input} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {Link, useHistory} from "react-router-dom";
import {useAuthDispatch, useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {forgetPasswordSendMail} from "../../api/axiosAPIs";


function ForgetPassword(props) {
  let history = useHistory();
  const dispatch = useAuthDispatch()
  const {loading,} = useAuthState()

  const items = [
    {to: '', label: 'パスワード再設定'},
  ]

  const onFinish = async (data) => {
    let formData = new FormData()
    formData.append('email', data.email)
    forgetPasswordSendMail(formData)
      .then(response => {
        if (response.status === 200) {
          history.push(PageConstant.FORGET_PASSWORD_EMAIL_SEND)
        }
      })
  }

  return (
    <Fragment>
      <div className={"pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items} />
          <div className={"flex items-center justify-center"}>
            <span className={"text-2xl font-bold"}>パスワード再設定</span>
          </div>
          <div className={"flex items-center justify-center my-10 mx-0 md:mx-56"}>
            <span className={"text-sm"}>
              パスワード再設定の手続きを行います。
              登録済みのメールアドレスをご入力の上、「再設定メールを送信する」ボタンをクリックしてください。
              「パスワード再設定URL」を記載したメールをお送りいたします。
              受信後1時間以内にURLから再設定の手続きをお願いします。
            </span>
          </div>
          <div className="bg-white mx-auto" style={{ borderRadius: 20 }}>
            <Form className={"px-6i pt-16i pb-4i md:p-35pxi"} onFinish={onFinish}>
              <div
                className={
                  "flex flex-col md:flex-row items-start md:items-center justify-center gap-2 md:gap-0 mb-4"
                }
              >
                <div className={"mr-12"} style={{ marginTop: -24 }}>
                  <span className={"font-bold text-sm"}>
                    登録済みメールアドレス
                  </span>
                </div>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: props.intl.formatMessage({
                        id: "alert.fieldRequired",
                      }),
                    },
                  ]}
                  className={"w-full mb-2i md:mb-24pxi"}
                >
                  <Input
                    placeholder={"メールアドレスを入力"}
                    style={{ height: 45 }}
                    className={"w-full md:w-450px"}
                  />
                </Form.Item>
              </div>
              <div className={"flex justify-center"}>
                <button
                  type="submit"
                  className={
                    "flex items-center justify-center bg-yellow btn-secondary-semi-round text-black text-lg w-full md:w-315px"
                  }
                  style={{ height: 55 }}
                >
                  <span className={"text-base font-bold"}>
                    再設定メールを送信する
                  </span>
                </button>
              </div>
              <div className={"flex justify-center my-5"}>
                <Link to={PageConstant.LOGIN}>
                  <span className="underline">キャンセル</span>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(ForgetPassword)