import React, {Fragment, useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const Slide = (props) => {
  let slideRef = null

  const settings = {
    // customPaging: function(i) {
    //   return (
    //     <a>
    //       <img src={props.imageList[i]} />
    //     </a>
    //   );
    // },
    // dotsClass: "slick-dots slick-thumb",
    dots: false,
    arrows: false,
    className: "center",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: props.initialSlide,
    speed: 800,

  };

  const onClickPrev = e => {
    if (slideRef) {
      slideRef.slickPrev()
    }
  }

  const onClickNext = e => {
    if (slideRef) {
      slideRef.slickNext()
    }
  }


  useEffect(() => {
  }, []);

  return (
    <Fragment>
      {props.imageList && props.imageList.length > 0 && (
        <div className="relative mx-0 md:mx-24 py-0 md:py-16">
          <div
            className="absolute z-10 left-4 md:left-0 mt-40 md:mt-calc-slide_button"
            onClick={onClickPrev}
          >
            <img
              alt=""
              style={{ width: 42, height: 42 }}
              src={require("../../assets/images/btn_prev.png")}
            />
          </div>
          <div
            className="absolute z-10 right-4 md:right-0 mt-40 md:mt-calc-slide_button"
            onClick={onClickNext}
          >
            <img
              alt=""
              style={{ width: 42, height: 42 }}
              src={require("../../assets/images/btn_next.png")}
            />
          </div>
          <Slider
            ref={(c) => (slideRef = c)}
            {...settings}
            style={{}}
            className="bobo"
          >
            {props.imageList &&
              props.imageList.map((item, key) => (
                <div key={key}>
                  <div
                    className={"flex justify-center items-center mx-2 md:mx-0"}
                  >
                    <img
                      alt=""
                      src={item}
                      className="md:w-calc_theme_slice md:h-calc_theme_slice"
                    />
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      )}
    </Fragment>
  );
}
