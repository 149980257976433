import React, {Fragment} from 'react';
import {Col} from "antd";

export const FormLabel = (props) => {

  return (
    <Fragment>
      <Col span={6} xs={{ offset: 0 }} sm={{ offset: 0 }} md={{ offset:2 }}>
        <div className={"flex items-center mt-2 mb-2 md:mb-0"}>
          {props.required && (
            <img alt="" src={require("../../assets/images/require.svg")} />
          )}
          {!props.required && (
            <div className={"hidden md:visible"} style={{ width: 45 }} />
          )}
          <span className={"text-base whitespace-no-wrap ml-2"}>
            {props.label}
          </span>
        </div>
      </Col>
    </Fragment>
  );
}
