import React, {Fragment} from 'react';

export const SpanBr = (props) => {
  return (
    <Fragment>
      {
        props.name && (
          <span dangerouslySetInnerHTML={{__html: props.name.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>
        )
      }
    </Fragment>
  )
}
