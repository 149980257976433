import React, {Fragment, useState} from 'react';
import {Form, Input} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {resetPassword} from "../../api/axiosAPIs";


function ResetPassword(props) {
  let history = useHistory();
  const {loading,} = useAuthState()

  const queryParams = new URLSearchParams(props.location.search);
  const [state] = useState({id: queryParams.get('id'), email: queryParams.get('email')});

  const items = [
    {to: '', label: 'パスワード再設定'},
  ]

  const onFinish = (data) => {
    let formData = new FormData()
    formData.append('password', data.password)
    formData.append('password_confirmation', data.password)
    formData.append('email', state.email)
    resetPassword(state.id, formData)
      .then(response => {
        if (response.status === 200) {
          history.push(PageConstant.RESET_PASSWORD_FINISH)
        }
      })
  }

  return (
    <Fragment>
      <div className={"pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items} />
          <div className={"flex items-center justify-center"}>
            <span className={"text-2xl font-bold"}>パスワード再設定</span>
          </div>
          <div className={"flex items-center justify-center my-10 mx-0 md:mx-56"}>
            <span className={"text-sm"}>
              新しいパスワードを入力してください。
              ブラウザを閉じるとURLが無効となりますので、再度「パスワードを忘れた方はこちら」リンクからお手続きください。
            </span>
          </div>
          <div className="bg-white mx-auto" style={{ borderRadius: 20 }}>
            <Form className={"px-6i py-16i md:p-35pxi"} onFinish={onFinish}>
              <div
                className={
                  "flex flex-col md:flex-row items-start md:items-center justify-center gap-2 md:gap-0 mb-4"
                }
              >
                <div className={"mr-12 w-full"} style={{ marginTop: -24 }}>
                  <span className={"font-bold text-sm"}>新しいパスワード</span>
                </div>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: props.intl.formatMessage({
                        id: "alert.fieldRequired",
                      }),
                    },
                  ]}
                  className={"w-full mb-2i md:mb-24pxi"}
                >
                  <Input.Password
                    placeholder={"パスワードを入力"}
                    style={{ height: 45 }}
                    className={"w-full md:w-450px"}
                  />
                </Form.Item>
              </div>
              <div className={"flex justify-center"}>
                <button
                  type="submit"
                  className={
                    "flex items-center justify-center bg-yellow btn-secondary-semi-round text-black text-lg w-full md:w-315px"
                  }
                  style={{ height: 55 }}
                >
                  <span className={"text-base font-bold"}>
                    パスワードを再設定する
                  </span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(ResetPassword)