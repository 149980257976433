import React, {Fragment} from 'react';

export const GoogleSheet = (props) => {
  return (
    <Fragment>
      {/*https://docs.google.com/spreadsheets/d/e/2PACX-1vSbqlSmN-eCHZ2db8rUYpDVO8-7uPO6i5wUfSwqiJwoRMLVxXBaV7ZR97lEb-8c9lqmzHs11kHXXL3U/pubhtml*/}
      {
        props.url && (
          <div className={"relative w-full"} style={{height: 500}}>
                <iframe className={"absolute w-full"} style={{height: 500, left: -1}}
                        src={props.url + "?chrome=false&amp;headers=false&amp;widget=false&amp;dummy=" + Math.random()}>
                </iframe>
          </div>
        )
      }
    </Fragment>
  )
}
