import React, {Fragment, useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const SlideCenterMode = (props) => {
  let containerRef = useRef()
  const [containerWidth, setContainerWidth] = useState(-100);
  let slideRef = null

  const settings = {
    dots: true,
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: props.initialSlide,
    speed: 800,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          appendDots: (dots) => {
            const slideIndex = parseInt(
              dots.find((item) => item.props.className === "slick-active").key
            );
            return (
              <ul>{slideIndex < 3 ? dots.splice(0, 3) : dots.splice(3, 3)}</ul>
            );
          },
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const cssstyle = `
    .center .slick-center .slide-elem {
      opacity: 1;
      -ms-transform: scale(1.54);
      transform: scale(1.54);
      transition-duration: 1s;
    }
    `

  const onClickPrev = e => {
    if (slideRef) {
      slideRef.slickPrev()
    }
  }

  const onClickNext = e => {
    if (slideRef) {
      slideRef.slickNext()
    }
  }

  const getMLPrev = (width) => {
    return width > 600 ? width / 2 - 308 - 17 - width / 80 : -100
  }

  const getMLNext = (width) => {
    return width > 600 ? width / 2 + 308 - 17 + width / 80 : -100
  }


  const updateContainerWidth = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth)
    }
  }

  useEffect(() => {
    updateContainerWidth()

    function handleResize() {
      updateContainerWidth()
    }

    window.addEventListener('resize', handleResize)
  }, []);

  return (
    <Fragment>
      <div ref={containerRef} className="mx-auto">
        <div className="block sticky z-10" style={{marginLeft: getMLPrev(containerWidth), top: 300, maxWidth: 40}}
             onClick={onClickPrev}>
          <img alt="" src={require('../../assets/images/btn_prev.png')}/>
        </div>
        <div className="block sticky z-10" style={{marginLeft: getMLNext(containerWidth), top: 300, maxWidth: 40}}
             onClick={onClickNext}>
          <img alt="" src={require('../../assets/images/btn_next.png')}/>
        </div>
        <style>{cssstyle}</style>
        <Slider ref={c => (slideRef = c)} {...settings} style={{marginTop: -68}}>
          {
            props.imageList.map((item, key) => (
              <div key={key}
                   onClick={(e) => props.onItemClick(e, item)}
              >
                <div className={"slide-elem flex justify-center items-center mx-1"} style={{height: 350}}>
                  <img alt="" style={{width: props.width, height: props.height}} src={item.notification_image_url}/>
                </div>
              </div>
            ))
          }
        </Slider>
      </div>
    </Fragment>
  )
}
