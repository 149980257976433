import React, {Fragment} from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  border: 2px solid black;
  background-color: #3F75A0;
  text-align: center;
  padding: 5px 10px;
`

export const ThemeBox = (props) => {
  return (
    <Fragment>
      <Container>
        <span className={"text-base md:text-lg text-white"}>{props.title}</span>
      </Container>
    </Fragment>
  )
}
