import React, {Fragment, useState} from 'react';
import {Col, Form, Input, Radio, Row} from "antd";
import {FormLabel} from "./FormLabel";
import {useAuthState} from "../../context";
import {INQUIRY_CONTENTS, INQUIRY_CONTENTS_OTHER_KEY} from "../../constants/inquiryContents";
import {isLogined} from "../../helper/utils";

const {TextArea} = Input;

export const FormInquiryTextArea = (props) => {
  const {profile} = useAuthState();
  const [selectedValue, setSeletedValue] = useState(-1);

  const onChange = (e) => {
    setSeletedValue(e.target.value)
  }

  return (
    <Fragment>
      <Row className={"flex-coli md:flex-rowi"}>
        <FormLabel label={props.label} required={props.required} />
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          {!isLogined(profile) && (
            <Form.Item
              name={props.name}
              rules={[
                {
                  required: true,
                  message: props.intl.formatMessage({
                    id: "alert.fieldRequired",
                  }),
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 15, maxRows: 15 }}
                placeholder={props.placeholder}
              />
            </Form.Item>
          )}
          {isLogined(profile) && (
            <Fragment>
              <Form.Item
                name={props.name}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: props.intl.formatMessage({
                      id: "alert.fieldRequired",
                    }),
                  },
                ]}
              >
                <Radio.Group style={{ width: "100%" }} onChange={onChange}>
                  {INQUIRY_CONTENTS.map((item) => (
                    <div key={item.key} className={"my-3"}>
                      <Radio value={item.key}>{item.title}</Radio>
                    </div>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={props.name + "Name"}
                rules={[
                  {
                    required: selectedValue === INQUIRY_CONTENTS_OTHER_KEY,
                    message: props.intl.formatMessage({
                      id: "alert.fieldRequired",
                    }),
                  },
                ]}
              >
                <TextArea
                  disabled={selectedValue !== INQUIRY_CONTENTS_OTHER_KEY}
                  autoSize={{ minRows: 15, maxRows: 15 }}
                  placeholder={props.placeholder}
                />
              </Form.Item>
            </Fragment>
          )}
        </Col>
      </Row>
    </Fragment>
  );
}
