import React, {Fragment} from 'react';
import { GenereTag } from "../genere/GenereTag";
import { ProgramType } from "../program/ProgramType";
import{ Tag } from "../tag/Tag";
import {InstructorItem} from "./InstructorItem";
import {useHistory} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";

export const ThemeItem = (props) => {
  let history = useHistory();
  const onItemClick = (e, item) => {
    e.preventDefault()
    history.push(PageConstant.THEME_DETAIL, {data: item})
  }
  
  return (
    <Fragment>
      <div onClick={(e) => onItemClick(e, props.item)}>
        {/* {props.item.isNew === 1 && (
          <div
            className={
              "absolute md:relative right-0 md:right-auto mt-6 md:mt-0 flex justify-end mr-4"
            }
          >
            <img
              alt=""
              className={"z-10"}
              src={require("../../assets/images/mark_new.svg")}
            />
          </div>
        )} */}
        {/* {props.item.isNew === 0 && <div style={{ height: 88.88 }} />} */}
        <div className={"bg-white theme-item-card"}>
          {props.item.theme_image_urls && (
            <div
              className={"center-cropped-wrapper w-full h_198 md:h-198px position-relative"}
            >
              <ProgramType program = {props.item.program_types} />
              <img
                alt=""
                className={
                  "center-cropped w-full h-198px object-cover md:object-fill"
                }
                src={
                  props.item.theme_image_urls.length > 0
                    ? props.item.theme_image_urls[0]
                    : require("../../assets/images/column_thumbnail.png")
                }
              />
            </div>
          )}
          <div className={"pb-1 px-3"}>
            <div className={"flex items-center pt-2 pb-3"}>
               <GenereTag genere = {props.item}/> 
              {/* {props.item.tag
                .split(",")
                .slice(0, 3)
                .map((item, key) => (
                  <div key={key} className={"mr-3"}>
                    <Tag item={item} />
                  </div>
                ))} */}
            </div>
            {/* {props.item.theme_name && (
              <div className={"mb-2"}>
                <span className={"text-base md:text-lg"}>{props.item.theme_name}</span>
              </div>
            )} */}
            {props.item.instructor && (
              <div>
                <InstructorItem item={props.item.instructor} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
