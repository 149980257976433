import React, {Fragment} from 'react';
import styled from "styled-components";
import {TeacherItem} from "./TeacherItem";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 1rem;
`

export const TeacherList = (props) => {
  return (
    <Fragment>
      <GridContainer>
        {
          props.teacherList.map((item, key) => (
            <TeacherItem key={key} item={item}/>
          ))
        }
      </GridContainer>
    </Fragment>
  )
}
