import React, {Fragment} from 'react';
import {Form, Input} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {Link, useHistory} from "react-router-dom";
import {signup} from "../../api/axiosAPIs";
import {useAuthDispatch, useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";


function Signup(props) {
  let history = useHistory();
  const dispatch = useAuthDispatch()
  const {loading,} = useAuthState()

  const items = [
    {to: '', label: '新規会員登録'},
  ]

  const onFinish = async (data) => {
    let formData = new FormData()
    formData.append('email', data.email)
    formData.append('pattern', 'a') //aパターン
    try {
      let response = await signup(formData)
      if (response.status === 200) {
        history.push(PageConstant.SIGNUP_VIRTUAL_REGISTER)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      <div className={"pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items} />
          <div
            className="bg-white mx-auto w-full md:w-500px"
            style={{ borderRadius: 20, overflow: "hidden" }}
          >
            <div
              className={"flex items-center justify-center bg-blue"}
              style={{ height: 75 }}
            >
              <span className={"text-xl text-white font-bold"}>
                新規登録(仮登録)
              </span>
            </div>
            <Form onFinish={onFinish} className={"p-6i md:p-35pxi"}>
              <div className={"flex items-center"}>
                <span>
                  まずは仮登録を行います。ご入力いただいたメールアドレスに本登録の手順をお送りします。
                </span>
              </div>
              {/*メールアドレス*/}
              <div className={"mt-4 mb-2"}>
                <span className={"font-bold text-sm"}>メールアドレス</span>
              </div>
              <Form.Item
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: props.intl.formatMessage({
                      id: "alert.fieldRequired",
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={"メールアドレスを入力"}
                  style={{ height: 45 }}
                />
              </Form.Item>
              <div className={"flex justify-center"}>
                <button
                  type="submit"
                  className={
                    "flex items-center justify-center bg-yellow btn-secondary-semi-round text-black text-lg"
                  }
                  style={{ width: 315, height: 55 }}
                >
                  <span className={"font-bold"}>仮登録</span>
                </button>
              </div>
              <div className={"flex justify-center my-5"}>
                <span>
                  すでにアカウントをお持の方は
                  <Link to={PageConstant.LOGIN}>
                    <span className="underline">こちら</span>
                  </Link>
                </span>
              </div>
              <div>
                <span>
                  ※
                  ドメイン指定受信をされている場合、info@mietaplus.comからのメールを受信できるようご設定ください。
                  <br />※
                  お使いのメールソフトによっては、メールが「迷惑メール」フォルダに入る場合がございます。メールが届かない場合は、迷惑フォルダもご確認ください。
                </span>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(Signup)