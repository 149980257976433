import React, {Fragment} from 'react';
import {ThemeItem} from "./ThemeItem";
import styled from "styled-components";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 23px;
  grid-column-gap: 33px;
`
const GridItem = styled.div`
  width: 352px;
`

export const ThemeList = (props) => {
  return (
    <Fragment>
      <div className={"grid-toppage md:grid-toppage"}>
        {
          props.themeList.map((item, key) => (
            <div key={key} className="w-full md:w-calc_top_grid_ext">
              {
                item.id && (
                  <ThemeItem item={item}/>
                )
              }
            </div>
          ))
        }
      </div>
    </Fragment>
  )
}
