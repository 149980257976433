import React, {Fragment} from 'react';
import {Card, Col, Row, Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory, useLocation} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";
import InquiryFormTitle from "../inquiry/inquiryFormTitle";


function ThemeInquirySendFinish(props) {
  let history = useHistory();
  const item = useLocation().state.item

  const items = [
    {to: PageConstant.THEMES, label: 'MIETAN教材'},
    {to: '', label: item.theme_name},
    {to: '', label: 'お問い合わせ送信完了'},
  ]

  const onOk = () => {
    history.push(PageConstant.THEMES)
  }

  return (
    <Fragment>
      <div className={"px-6 md:px-32 pb-12 bg-yellow-light"}>
        <Breadcrumb items={items} />
        <InquiryFormTitle />
        <Card style={{ borderRadius: 20 }} className={"p-4i md:p-30pxi"}>
          <Spin spinning={null} size="large">
            <div className={"flex items-center justify-center "}>
              <span className={"text-light-blue text-lg font-bold"}>
                お問い合わせいただき
                <br className={"block md:hidden"} />
                ありがとうございます
              </span>
            </div>
            <div className={"bg-blue h-px my-4 mx-0 md:mx-32"} />
            <span
              className={"flex justify-start md:justify-center text-sm py-1"}
            >
              内容を確認後、折り返しご連絡させていただきます。
            </span>
            <span
              className={"flex justify-start md:justify-center text-sm py-1"}
            >
              ※お問い合わせ状況やご質問内容によって、
            </span>
            <span
              className={"flex justify-start md:justify-center text-sm py-1"}
            >
              お返事を差し上げるのにお時間がかかってしまう場合がございます。
            </span>
          </Spin>
        </Card>
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 8, offset: 8 }}
          >
            <div className={"mt-10 flex items-center justify-center"}>
              <button
                className={
                  "flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl w-full md:w-450px"
                }
                onClick={onOk}
              >
                <span className={"mr-8"}>MIETAN教材一覧に戻る</span>
                <img
                  alt=""
                  src={require("../../assets/images/circle_arrow_yellow.svg")}
                />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default injectIntl(ThemeInquirySendFinish)