import React, {Fragment} from 'react';
import styled from "styled-components";

const ProgramContainer = styled.div`
  padding: 7px 17px 5px 18px;
  border-radius-top-right: 5px;
  background-color: #1e425f;
  position: absolute;
`

const ProgramContainerSpan = styled.span`
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 0.33px;
  text-align: left;
  color: #fff;
`

export const ProgramType = (props) =>{
  return (
    <Fragment>
      {props.program && (
      <ProgramContainer className={"h-28px md:h-27px flex items-center"}>
        <Fragment>
          <ProgramContainerSpan className={"text-white whitespace-no-wrap"}>
            {props.program.name}
          </ProgramContainerSpan>
        </Fragment>
      </ProgramContainer>
      )}
    </Fragment>
  )
}