import React, {Fragment, useEffect, useState} from 'react';
import {Input, Modal} from "antd";

export const DeleteConfirm = (props) => {
  return (
    <Fragment>
      <Modal
        className={"modal-school"}
        title={null}
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        footer={null}
        centered
        width={675}
      >
        <div className={"px-6 py-4"}>
          <div className={"flex items-center justify-center"}>
            <span className={"text-xl font-bold text-light-blue"}>この教員アカウントを削除します</span>
          </div>
          <div className={"flex items-center justify-center"}>
            <span className={"text-xl font-bold text-light-blue"}>よろしいですか？</span>
          </div>
          <div className={"bg-blue my-4 h-px"}/>
          <div className={"flex items-center justify-center"}>
            <button
              className={"flex items-center justify-center btn-primary-semi-round bg-blue"}
              style={{width: 250, height: 55}}
              onClick={props.onDelete}
            >
              <span className={"text-white text-base font-bold"}>削除する</span>
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}
