import React, {Fragment} from 'react';
import {Button, Col, Form, Radio, Row, Spin} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {FormInput} from "../../components/form/FormInput";
import {FormLabel} from "../../components/form/FormLabel";
import {addTeacher, isExistTeacher} from "../../api/axiosAPIs";
import {T_Admin, T_Viewer} from "../../constants/Permission";
import {ERROR, openNotificationWithIcon} from "../../components/common/Messages";


function TeacherManageAdd(props) {
  const {profile} = useAuthState();
  let history = useHistory();

  const items = [
    {to: PageConstant.TEACHER_MANAGE, label: '教員管理'},
    {to: '', label: '教員アカウント新規発行（仮登録）完了'},
  ]

  const onAddPublish = async (data) => {
    if (data.teachers.length > 0) {
      try {
        for (let i = 0; i < data.teachers.length; i++) {
          const teacher = data.teachers[i]
          const response = await isExistTeacher(teacher)
          if (response.data === 'exist') {
            openNotificationWithIcon(ERROR, teacher.email + "は既存ですので、登録に失敗しました。")
            return
          }
        }

        data.teachers.map(async (teacher) => {
          let formData = new FormData()
          formData.append('name', teacher.teacher_name)
          formData.append('teacher_name', teacher.teacher_name)
          formData.append('email', teacher.email)
          formData.append('permission', teacher.permission)
          formData.append('pattern', 'c') //cパターン
          formData.append('prefecture_id', profile.teacher.prefecture_id)
          formData.append('school_id', profile.teacher.school_id)
          formData.append('contracted_school_id', profile.teacher.contracted_school_id)
          await addTeacher(formData)
        })
        history.push(PageConstant.TEACHER_MANAGE_ADD_SEND)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-blue-light"}>
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"rounded-xl bg-white overflow-hidden"}>
            <Spin spinning={null} size="large">
              <div className={"flex items-center justify-center py-4 bg-blue"}>
                <span className={"ml-4 text-white text-lg font-bold"}>教員管理</span>
              </div>
              <div className={"py-12 px-32"}>
                <div className={"mb-8"}>
                  <span className={"text-base font-bold text-blue"}>教員アカウントの新規登録</span>
                </div>
                <div className={"ml-4 mb-8"}>
                </div>
                <div className={"ml-4 mb-8"}>
                  <span
                    className={"text-sm text-black"}>
                    ※ ドメイン指定受信をされている場合、info@mietaplus.comからのメールを受信できるようご設定ください。<br/>
                    ※ お使いのメールソフトによっては、メールが「迷惑メール」フォルダに入る場合がございます。メールが届かない場合は、迷惑フォルダもご確認ください。
                  </span>
                </div>

                <Form
                  onFinish={onAddPublish}
                >
                  <Form.List name="teachers" initialValue={[""]}>
                    {(fields, {add, remove}) => (
                      <Fragment>
                        {
                          fields.map(({key, name, ...restField}) => (
                            <Fragment key={key}>
                              <div className={"relative"}>
                                <div className={"absolute flex justify-end w-full"}>
                                  <div className={"py-3 px-3 cursor-pointer"} onClick={() => remove(name)}>
                                    <img alt={""} src={require('../../assets/images/close.png')}/>
                                  </div>
                                </div>
                              </div>
                              <div className={"rounded-xl pt-10 pb-4 mb-4"} style={{background: '#F8F8F8'}}>
                                <FormInput
                                  label={props.intl.formatMessage({id: 'form.item.teacher.name'})}
                                  placeholder={props.intl.formatMessage({id: 'form.item.teacher.name'})}
                                  name={[name, 'teacher_name']}
                                  intl={props.intl}
                                  required={true}
                                  readOnly={false}
                                />
                                <FormInput
                                  label={props.intl.formatMessage({id: 'form.item.email'})}
                                  placeholder={props.intl.formatMessage({id: 'form.item.email'})}
                                  name={[name, 'email']}
                                  intl={props.intl}
                                  required={true}
                                  readOnly={false}
                                />
                                <Row>
                                  <FormLabel label={'権限'} required={true}/>
                                  <Col span={12}>
                                    <Form.Item
                                      name={[name, 'permission']}
                                      initialValue={T_Admin}
                                      required={true}
                                    >
                                      <Radio.Group>
                                        <Radio value={T_Admin} style={{width: 200}}>管理者</Radio>
                                        <Radio value={T_Viewer}>閲覧のみ</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Fragment>
                          ))}
                        <Form.Item>
                          <div className={"flex justify-end w-full py-8"}>
                            <Button
                              style={{width: 220, height: 38, borderRadius: 19, border: '2px solid #3F75A0'}}
                              onClick={() => add()}>
                              <span className={"text-blue text-xs font-bold"}>教員アカウントの追加</span>
                            </Button>
                          </div>
                        </Form.Item>
                      </Fragment>
                    )}
                  </Form.List>
                  {
                    profile.permission > T_Viewer && (
                      <div className={"flex justify-center w-full"}>
                        <button
                          type="submit"
                          className={"flex items-center justify-center btn-primary-semi-round bg-blue"}
                          style={{width: 315, height: 55}}
                        >
                          <img alt={""} src={require('../../assets/images/avatar.svg')}/>
                          <span className={"ml-4 text-white text-base font-bold"}>教員アカウントの新規発行</span>
                        </button>
                      </div>
                    )
                  }
                </Form>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(TeacherManageAdd)