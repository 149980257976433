import React, {Fragment, useEffect, useState} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory, useLocation} from "react-router-dom";
import {InstructorLayout} from "../../components/Instructor/InstructorLayout";
import {ThemeList} from "../../components/theme/ThemeList";
import {getInstructorItem, getThemeListByInstructor} from "../../api/axiosAPIs";
import _ from "lodash";
import {useAuthState} from "../../context";


function InstructorDetail(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const data = useLocation().state.data
  const [instructor, setInstructor] = useState({});
  const [state, setState] = useState({page: 1, rowsPerPage: 3, themeList: [], total: 0});

  const items = [
    {to: '', label: '探究学習コンテンツ'},
    {to: '', label: data.instructor_name + 'プロフィール'},
  ]

  const fetchInstructorItem = async (id) => {
    const response = await getInstructorItem(id)
    if (!_.isEmpty(response.data)) {
      if (response.data.data) {
        setInstructor(response.data.data)
      }
    }
  }

  const getParam = (page, rowsPerPage) => {
    return {
      page: page,
      per_page: rowsPerPage,
      instructor_id: data.id,
    }
  }

  const fetchThemeList = async (data) => {
    const response = await getThemeListByInstructor(data)
    if (!_.isEmpty(response.data)) {
      if (response.data.data) {
        const themes = response.data.data
        if (themes.length > 0 && themes.length < 3) {
          for (let i = 0; i < 3 - themes.length; i++) {
            themes.push({})
          }
        }
        setState({
          page: data.page,
          rowsPerPage: data.per_page,
          themeList: themes,
          total: response.data.total,
        })
      }
    }
  }

  useEffect(() => {
    fetchInstructorItem(data.id)
      .catch(console.error);

    fetchThemeList(getParam(state.page, state.rowsPerPage))
      .catch(console.error);
  }, []);

  const onChange = (page) => {
    fetchThemeList(getParam(page, state.rowsPerPage))
      .catch(console.error);
  }

  return (
    <Fragment>
      <div className={"pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items} />
          <InstructorLayout instructor={instructor} />
        </div>
      </div>
      <div className={"py-12 bg-home-theme"}>
        <div className={"container"}>
          <div
            className={
              "flex items-center justify-center bg-yellow btn-secondary-semi-round h-65px w-full"
            }
          >
            <span className={"font-bold text-2xl text-black"}>
              この講師に関連するテーマ
            </span>
          </div>
          <div className={"mt-4 relative"}>
            <button
              className="absolute -left-10px md:-left-42px"
              style={{ top: "50%", width: 42, height: 42 }}
              disabled={state.page === 1}
              onClick={() => onChange(state.page - 1)}
            >
              <img alt="" src={require("../../assets/images/btn_prev.png")} />
            </button>
            <ThemeList themeList={state.themeList} />
            <button
              className="absolute -right-20px md:-right-42px"
              style={{ top: "50%", width: 42, height: 42 }}
              disabled={state.page * state.rowsPerPage >= state.total}
              onClick={() => onChange(state.page + 1)}
            >
              <img alt="" src={require("../../assets/images/btn_next.png")} />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default injectIntl(InstructorDetail)