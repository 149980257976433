import React, {Fragment} from 'react';
import {useHistory} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";
import InquiryForm from "../../pages/inquiry/inquiryForm";

export const Top = () => {
  let history = useHistory();

  const gotoSignup = () => {
    history.push(PageConstant.SIGNUP)
  }

  return (
    <Fragment>
      <div className="flex flex-col md:flex-row justify-center pt-16 pb-6 md:pb-20 px-6 md:px-32">
        <div>
          <div>
            <img
              alt=""
              src={require("../../assets/images/home_logo.png")}
              className="w-full md:w-auto"
            />
          </div>
          <div
            className={
              "py-8 text-base md:text-lg md:max-w-500 leading-loose md:leading-relaxed"
            }
          >
            <div>
              MIETANは、探究学習に関する記事や事例、テーマ例及び講師例の紹介、管理ツール等を提供する“実践型“の探究プラットフォームです。
            </div>
            <div>
              今後も教員の皆さまからのご意見を参考に、様々なコンテンツやツールを開発してまいりますので、是非ともご登録よろしくお願い致します。
            </div>
          </div>
          <div>
            <button
              className={
                "flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl w-full md:w-450px"
              }
              onClick={gotoSignup}
            >
              <span className={"mr-8"}>新規登録はこちら</span>
              <img
                alt=""
                src={require("../../assets/images/circle_arrow_yellow.svg")}
              />
            </button>
          </div>
        </div>
        <div className={"mt-16 md:mt-0 md:pl-20"}>
          <img
            alt=""
            src={require("../../assets/images/home_banner2x.png")}
            className={"w-full md:w-560px"}
          />
        </div>
      </div>
      {/*ご提供メニュー*/}
      <div className={"pt-16 pb-6 md:pb-20 px-6 md:px-32"}>
        <div className={"flex justify-center pb-4"}>
          <img
            alt=""
            src={require("../../assets/images/top_part_1_menu.svg")}
          />
        </div>
        <div
          className={
            "flex flex-col md:flex-row justify-center mt-8 md:mt-0 pb-16 md:pb-4 gap-16 md:gap-0"
          }
        >
          <div>
            <img
              alt=""
              src={require("../../assets/images/top_part_1_item_1.svg")}
              className={"w-11/12 md:w-full mx-auto md:mx-0"}
            />
          </div>
          <div className={"md:pl-20 md:pr-20"}>
            <img
              alt=""
              src={require("../../assets/images/top_part_1_item_2.svg")}
              className={"w-11/12 md:w-full mx-auto md:mx-0"}
            />
          </div>
          <div>
            <img
              alt=""
              src={require("../../assets/images/top_part_1_item_3.svg")}
              className={"w-11/12 md:w-full mx-auto md:mx-0"}
            />
          </div>
        </div>
      </div>

      {/*コラム*/}
      <div className={"pt-16 pb-20 px-6 md:px-32 bg-home-theme"}>
        <div className={"flex justify-center"}>
          <img
            alt=""
            src={require("../../assets/images/top_part_2_menu.svg")}
            className={"w-full md:w-auto hidden md:block"}
          />
          <img
            alt=""
            src={require("../../assets/images/top_part_2_menu_sp.svg")}
            className={"w-full md:w-auto block md:hidden"}
          />
        </div>
        <div
          className={
            "flex justify-center text-blue text-base md:text-lg font-bold py-8 leading-loose md:leading-relaxed"
          }
        >
          全国の学校における探究の先進的な取り組み事例の紹介、探究に関するオリジナル記事等、探究に関するあらゆる情報をお届けします。
        </div>
        <div className={"flex justify-center flex-col md:flex-row"}>
          <div className={""}>
            <img
              alt=""
              src={require("../../assets/images/top_part_2_item_1.svg")}
              className={"w-full md:w-530px md:h-530px"}
            />
          </div>
          <div className={"pl-0 md:pl-20"}>
            <img
              alt=""
              src={require("../../assets/images/top_part_2_item_2.svg")}
              className={"w-full md:w-530px md:h-530px"}
            />
          </div>
        </div>
      </div>

      {/*教材*/}
      <div className={"pt-16 pb-20 px-6 md:px-32 bg-yellow-light"}>
        <div className={"flex justify-center"}>
          <img
            alt=""
            src={require("../../assets/images/top_part_3_menu.svg")}
            className={"w-full md:w-auto hidden md:block"}
          />
          <img
            alt=""
            src={require("../../assets/images/top_part_3_menu_sp.svg")}
            className={"w-full md:w-auto block md:hidden"}
          />
        </div>
        <div
          className={
            "flex justify-center text-blue text-base md:text-lg font-bold py-8 leading-loose md:leading-relaxed"
          }
        >
          MIETANで提供しているテーマの一部を掲載しております。各テーマの学習内容、担当講師のプロフィール、ワークシート(抜粋版)をご覧頂けます。
        </div>
        <div className={"flex justify-center flex-col md:flex-row"}>
          <div className={""}>
            <img
              alt=""
              src={require("../../assets/images/top_part_3_item_1.svg")}
              className={"w-full md:w-530px md:h-530px"}
            />
          </div>
          <div className={"pt-16 md:pt-0 pl-0 md:pl-20"}>
            <img
              alt=""
              src={require("../../assets/images/top_part_3_item_2.svg")}
              className={"w-full md:w-530px md:h-530px"}
            />
          </div>
        </div>
      </div>

      {/*プログラム管理*/}
      <div className={"pt-16 pb-20 px-6 md:px-32"}>
        <div className={"flex justify-center"}>
          <img
            alt=""
            src={require("../../assets/images/top_part_4_menu.svg")}
            className={"w-full md:w-auto hidden md:block"}
          />
          <img
            alt=""
            src={require("../../assets/images/top_part_4_menu_sp.svg")}
            className={"w-full md:w-auto block md:hidden"}
          />
        </div>
        <div
          className={
            "flex justify-center text-blue text-base md:text-lg font-bold pt-8 pb-16 leading-loose md:leading-relaxed"
          }
        >
          MIETANプログラムの導入校には、探究を進める上で必要なツールを全てまとめた教員向けページ及び生徒向けページをご提供しております。
        </div>
        <div className={"flex justify-center"}>
          <img
            alt=""
            src={require("../../assets/images/top_part_4_item_1.svg")}
            className={"hidden md:block"}
          />
          <img
            alt=""
            src={require("../../assets/images/sp_top_part_4_item_1.svg")}
            className={"block md:hidden"}
          />
        </div>
        <div
          className={
            "flex justify-center text-blue text-base md:text-lg font-bold py-16 leading-loose md:leading-relaxed"
          }
        >
          プログラム実施のための教員-生徒間 及び
          教員-弊社間における情報共有を円滑行うための管理ツールです。なお、生徒のアカウント作成は不要です。
        </div>
        <div className={"flex justify-center"}>
          <img
            alt=""
            src={require("../../assets/images/top_part_4_item_2.svg")}
            className={"hidden md:block"}
          />
          <img
            alt=""
            src={require("../../assets/images/sp_top_part_4_item_2.svg")}
            className={"block md:hidden"}
          />
        </div>
      </div>

      {/**/}
      <div className={"pt-4 pb-20 px-6 md:px-32 bg-home-theme"}>
        <div
          className={
            "flex justify-center text-blue text-base md:text-lg text-center md:text-left font-bold pt-8 leading-loose md:leading-relaxed"
          }
        >
          MIETANは、全国の中学校・高校向けに探究学習プログラムを提供している
        </div>
        <div
          className={
            "flex justify-center text-blue text-base md:text-lg font-bold pb-8 leading-loose md:leading-relaxed"
          }
        >
          株式会社ミエタが運営しております。
        </div>
        <div className={"flex justify-center"}>
          <div className={""}>
            <img
              alt=""
              src={require("../../assets/images/top_part_5_item_1.svg")}
              className={"hidden md:block"}
            />
            <img
              alt=""
              src={require("../../assets/images/sp_top_part_5_item_1.svg")}
              className={"block md:hidden"}
            />
          </div>
        </div>
        <div className={"flex justify-center pt-16"}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            ZZ
            href={"https://www.mietaplus.com/"}
            className={
              "flex items-center justify-center bg-yellow btn-secondary-round h-75px text-white text-xl cursor-pointer w-full md:w-450px"
            }
          >
            <span className={"mr-8"}>コーポレートサイト</span>
            <img
              alt=""
              src={require("../../assets/images/circle_arrow_blue.png")}
            />
          </a>
        </div>
      </div>

      {/*お問い合わせ*/}
      <InquiryForm isChild={true} />
    </Fragment>
  );
}