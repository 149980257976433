import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";
import {useAuthState} from "../../context";

export const Breadcrumb = (props) => {
  const {profile} = useAuthState();

  return (
    <Fragment>
      <div className={"flex items-center py-4 md:py-8 flex-wrap"}>
        {
          !props.isChild && (
            <Fragment>
              <div className={"mr-2"}>
                <Link to={PageConstant.HOME}>
                  TOP
                </Link>
              </div>
              {
                props.items.map(({to, label}, index, row) => {
                    return <Fragment key={index}>
                      <span className={"mr-2"}>></span>
                      <div className={"mr-2"}>
                        {
                          (to !== '') && (
                            <Link to={to}>
                              {label}
                            </Link>
                          )
                        }
                        {
                          (to === '') && (
                            <span>{label}</span>
                          )
                        }
                      </div>
                    </Fragment>
                  }
                )
              }
            </Fragment>
          )
        }
      </div>

    </Fragment>
  )
}
