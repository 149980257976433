import React, {Fragment} from 'react';
import {Modal} from "antd";
import {InstructorLayout} from "../Instructor/InstructorLayout";

export const InstructorDetailModal = (props) => {
  return (
    <Fragment>
      <Modal
        className={"modal-school"}
        title={null}
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        footer={null}
        centered
        width={'calc(100vw - 16rem)'}
      >
        <InstructorLayout
          instructor={props.instructor}
        />
      </Modal>
    </Fragment>
  )
}
