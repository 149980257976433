import React, {Fragment} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory, useLocation} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {Slide} from "../../components/Slide/Slide";
import styled from "styled-components";
import {ThemeBox} from "../../components/theme/ThemeBox";
import {InstructorItem} from "../../components/Instructor/InstructorItem";
import {ArrowItem} from "../../components/common/ArrowItem";
import {StepTitle} from "../../components/lesson/StepTitle";
import {SpanBr} from "../../components/common/SpanBr";
import {ColumnBox} from "../../components/column/ColumnBox";
import YoutubeEmbed from "../../components/youtube/YoutubeEmbed";
import {AttachItem} from "../../components/common/AttachItem";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
`
const GridItem = styled.div`
  display: flex;
  justify-content: center;
  width: calc((100vw - 32rem - 3rem) * 0.33333);
`

function TeaserLesson(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const {data, item} = useLocation().state
  const items = [
    {to: '', label: 'プログラム管理'},
    {to: '', label: data.program_name},
    {to: '', label: item.lesson_name},
  ]

  const lesson_image_urls = item.lesson_image_urls.slice(0, 3)
  if (lesson_image_urls.length < 3) {
    for (let i = 0; i < 3 - lesson_image_urls.length; i++) {
      lesson_image_urls.push("")
    }
  }
  const steps = [{section_name: "事前ワーク"}, {section_name: "本講義"}]

  return (
    <Fragment>
      <div className={"pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"flex justify-center items-center py-8"}>
            <span className={"font-bold text-black text-3xl"}>{item.lesson_name}</span>
          </div>
          <div className={"rounded-xl bg-white"}>
            <Slide
              initialSlide={0}
              imageList={item.lesson_image_urls}
            />
            <div className={"px-32 pb-12"}>
              <GridContainer>
                {
                  lesson_image_urls.map((url, key) => (
                    <GridItem key={key}>
                      {
                        url !== '' && (
                          <img alt="" src={url}/>
                        )
                      }
                    </GridItem>
                  ))
                }
              </GridContainer>
            </div>
            <div className={"px-48"}>
              <ThemeBox title={'ジャンル：国際開発・SDGs'}/>
              <div className={"pt-12"}>
                <span className={"text-base leading-8"}>
                  <SpanBr
                    name={'”エチオピアンローズ を題材に、アフリカの様々な課題を知りながら現地で起きている課題”の解決に取り組みます。 SDGsの観点であったり、地域ビジネスの観点などから、これからの社会で求められる視点を深めていきます。あなたの一つのアイディアから生まれる価値を一緒に見つけていきましょう'}/>
                </span>
                {
                  item.instructors && (
                    item.instructors.map((instructor, key) => (
                      <div key={key} className={"py-8"}>
                        <InstructorItem
                          item={instructor}
                          onItemClick={() => {
                          }}
                        />
                      </div>
                    ))
                  )
                }
              </div>
            </div>

            {/*授業情報*/}
            <div className={"px-32 py-12"}>
              <ArrowItem title={"授業情報"}/>
              <div className={"px-16 py-8 text-base leading-8"}>
                <SpanBr name='事前ワークを3つ受講し、その中から'/>
                {/*{*/}
                {/*  lessonData.remark && (*/}
                {/*    <div className={"py-6"}>*/}
                {/*      <BikoItem content={lessonData.remark}/>*/}
                {/*    </div>*/}
                {/*  )*/}
                {/*}*/}
              </div>
            </div>

            {/*授業ステップ*/}
            <div className={"flex items-center justify-center bg-blue py-4"}>
              <span className={"text-2xl text-white font-bold"}>授業ステップ</span>
            </div>
            <div className={"px-32 pb-16"}>
              <div className={"py-8 px-16"}>
                <StepTitle titles={steps.map(item => item)}/>
              </div>

              <div className={"bg-gray-600 h-px mb-4"}/>

              {/*step1*/}
              <div className={"px-16"}>
                <div className={"pt-8 pb-4 flex items-center"}>
                  <ColumnBox title={'2022.04.25'}/>
                  <span className={"ml-4 text-xl text-yellow font-bold"}>{'事前ワーク'}</span>
                </div>
                <div className={"flex items-center py-6"}>
                  <img alt="" src={require('../../assets/images/step1.png')}/>
                  <span className={"text-base text-blue font-bold ml-4"}>動画視聴(15分)</span>
                </div>
                <span className={"text-base"}>まずはこちらの動画を視聴しましょう</span>
                <div className={"py-6"}>
                  <YoutubeEmbed embedId="5DHIONY75wE"/>
                </div>
                <div className={"flex items-center py-6"}>
                  <img alt="" src={require('../../assets/images/step2.png')}/>
                  <span className={"text-base text-blue font-bold ml-4"}>個人ワーク(15分)</span>
                </div>
                <span className={"text-base"}>【ワークシートA】の個人ワーク①に取り組みましょう！</span>
                <div className={"flex items-center py-6"}>
                  <img alt="" src={require('../../assets/images/step3.png')}/>
                  <span className={"text-base text-blue font-bold ml-4"}>グループワーク(20分) </span>
                </div>
                <span className={"text-base"}>【ワークシートA】のグループワーク①に取り組みましょう！</span>
                <div className={"py-8"}>
                  <AttachItem readonly={true} item={["【ワークシートA】事前ワーク用.xlsx"]}/>
                </div>
              </div>

              <div className={"bg-gray-600 h-px mb-4"}/>

              {/*step2*/}
              <div className={"px-16"}>
                <div className={"pt-8 pb-4 flex items-center"}>
                  <ColumnBox title={'2022.05.09'}/>
                  <span className={"ml-4 text-xl text-yellow font-bold"}>{'本講義'}</span>
                </div>
                <div className={"flex items-center py-6"}>
                  <img alt="" src={require('../../assets/images/step1.png')}/>
                  <span className={"text-base text-blue font-bold ml-4"}>08:50-09:20　講義</span>
                </div>
                <span className={"text-base"}>大事な点はメモをとりながら講義を聴きましょう！</span>
                <div className={"flex items-center py-6"}>
                  <img alt="" src={require('../../assets/images/step2.png')}/>
                  <span className={"text-base text-blue font-bold ml-4"}>09:30-10:20　グループワーク②</span>
                </div>
                <span className={"text-base"}>【ワークシートB】のグループワーク②に取り組みましょう！</span>
                <div className={"flex items-center py-6"}>
                  <img alt="" src={require('../../assets/images/step3.png')}/>
                  <span className={"text-base text-blue font-bold ml-4"}>10:30-12:00　グループワーク</span>
                </div>
                <span className={"text-base"}>【ワークシートB】のグループワーク③に取り組み、プレゼン用シートを仕上げていきましょう！</span>
                <div className={"flex items-center py-6"}>
                  <img alt="" src={require('../../assets/images/step4.png')}/>
                  <span className={"text-base text-blue font-bold ml-4"}>13:00-13:20　プレゼン練習</span>
                </div>
                <span className={"text-base"}>13:30からのプレゼンに向けて、最終調整と練習をしましょう！</span>
                <div className={"flex items-center py-6"}>
                  <img alt="" src={require('../../assets/images/step5.png')}/>
                  <span className={"text-base text-blue font-bold ml-4"}>13:30-14:30　プレゼン&講師フィードバック</span>
                </div>
                <div className={"py-8"}>
                  <AttachItem readonly={true} item={["【ワークシートB】本講義用.xlsx"]}/>
                </div>
              </div>

              <div className={"bg-gray-600 h-px"}/>

            </div>
          </div>
          <div className={"flex justify-center pt-12"}>
            <button
              className={"flex items-center justify-center bg-blue btn-primary-round h-75px text-white text-xl"}
              style={{width: 300}}
              onClick={history.goBack}
            >
              <span>戻る</span>
              <img alt="" className={"absolute "} style={{marginLeft: 140}}
                  src={require('../../assets/images/circle_arrow_yellow.svg')}/>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(TeaserLesson)