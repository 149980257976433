import React, {Fragment} from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  border: 2px solid #1E425F;
  padding: 1rem 2rem;
  width: 100%;
`
//備考
export const BikoItem = (props) => {
  return (
    <Fragment>
      <Container>
        <div>
          <span className={"text-lg text-blue"}>【備考】</span>
        </div>
        <div className={"px-4 pt-2"}>
          <span className={"text-base text-black"}>{props.content}</span>
        </div>
      </Container>
    </Fragment>
  )
}
