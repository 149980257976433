import React, { Fragment } from "react";
import { ColumnBox } from "./ColumnBox";
import { PageConstant } from "../../constants/PageConstant";
import { useHistory } from "react-router-dom";

export const ColumnItem = (props) => {
  let history = useHistory();
  const onItemClick = (e, item) => {
    e.preventDefault();
    history.push(PageConstant.COLUMN_DETAIL, { data: item });
  };

  return (
    <Fragment>
      <div style={{ cursor: "pointer",}} onClick={(e) => onItemClick(e, props.item)}>
        <div className="mb-3">
          <ColumnBox title={props.item.public_start_date} />
        </div>
        <div
          className={"bg-white w-full"}
          style={{ borderRadius: 20, overflow: "hidden", border:"solid 1px #d1d1d1" }}
        >
          <div className={ "center-cropped-wrapper w-full" }>
            <img
              alt=""
              className={"center-cropped w-full h_283 object-cover md:object-fill"}
              src={
                props.item.notification_image_url !== ""
                  ? props.item.notification_image_url
                  : require("../../assets/images/column_thumbnail.png")
              }
            />
          </div>
          {/* <div className={"pb-4 px-4 mt-4"}>
            <div className={"my-2"}>
              <span className={"text-sm md:text-lg"}>{props.item.title}</span>
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};
