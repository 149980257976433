import React, {Fragment} from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  background: #F8F8F8;
  padding: 1.5rem;
  width: 100%;
`
//備考
export const AttachItem = (props) => {
  const Kind = {
    WorkSheet: 0,
    PDF: 1,
  }

  const getName = (downloadUrl) => {
    const filename = downloadUrl.replace(/^.*[\\\/]/, '')
    return decodeURIComponent(filename)
  }

  const getNameWithoutExt = (downloadUrl) => {
    return getName(downloadUrl).split('.').slice(0, -1).join('.')
  }

  const getKind = (downloadUrl) => {
    const extension = downloadUrl.split('.').pop()
    switch (extension) {
      case 'xls':
      case 'xlsx':
        return Kind.WorkSheet;
      case 'pdf':
        return Kind.PDF;
    }
  }

  const onDownload = (downloadUrl) => {
    if (!props.readonly) {
      fetch(downloadUrl, {
        method: "GET",
        headers: {}
      })
        .then(response => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", getName(downloadUrl))
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  return (
    <Fragment>
      <Container>
        <div>
          <span className={"text-lg text-black"}>【資料】</span>
        </div>
        {
          props.item.map((url, key) => (
            <Fragment key={key}>
              <div className={"px-4 pt-2 flex items-center cursor-pointer"}
                   onClick={() => onDownload(url)}>
                <img className={"mr-4"} alt={""} src={require('../../assets/images/point.png')}/>
                {
                  getKind(url) === Kind.WorkSheet && (
                    <img className={"mr-4"} alt={""} src={require('../../assets/images/attach_xls.png')}/>
                  )
                }
                {
                  getKind(url) === Kind.PDF && (
                    <img className={"mr-4"} alt={""} src={require('../../assets/images/attach_pdf.png')}/>
                  )
                }
                {
                  getKind(url) === Kind.WorkSheet && (
                    <span className={"text-base text-black"}>{getNameWithoutExt(url)}</span>
                  )
                }
                {
                  getKind(url) !== Kind.WorkSheet && (
                    <span className={"text-base text-black"}>{getNameWithoutExt(url)}</span>
                  )
                }
              </div>
            </Fragment>
          ))
        }
      </Container>
    </Fragment>
  )
}
