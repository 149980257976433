import React, {Fragment, useEffect, useState} from 'react';
import {Input, Modal} from "antd";
import {getSchoolList} from "../../api/axiosAPIs";
import _ from "lodash";

export const ModalSchool = (props) => {
  const [state, setState] = useState({keyword: '', schoolList: [], total: 0});

  const onChangeKeyword = (e) => {
    setState({keyword: e.target.value, schoolList: state.schoolList, total: state.total})
  }

  const onClickSearchButton = () => {
    fetchSchoolList()
  }

  const fetchSchoolList = () => {
    getSchoolList({keyword: state.keyword, page: 0, per_page: 10})
      .then(response => {
        if (!_.isEmpty(response.data)) {
          setState({
            keyword: state.keyword,
            schoolList: response.data.data,
            total: response.data.total,
          })
        }
      })
  }

  useEffect(() => {
  }, []);

  return (
    <Fragment>
      <Modal
        className={"modal-school"}
        title={null}
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        footer={null}
        centered
        width={1000}
      >
        <div className={"p-0 md:p-4"}>
          <div className={"flex items-center"}>
            <img alt="" src={require('../../assets/images/school_mark.png')}/>
            <span className={"text-xl font-bold ml-4 mt-1"}>所属学校を検索する</span>
          </div>
          <div className={"py-4"}>
            <span className={"text-sm alarm-color"}>所属学校名を検索する際には、学園名や学校法人名を含めず、正式な学校名(◯◯中学校、◯◯高等学校)と入力してください</span>
          </div>
          <div className={"p-6 md:p-10 bg-yellow-light"}>
            <div className={"flex flex-col md:flex-row items-start md:items-center gap-3 md:gap-0"}>
              <span className={"text-base font-bold"} style={{width: 100}}>キーワード</span>
              <Input placeholder={"所属学校名"} style={{height: 45}} onChange={onChangeKeyword} className={"w-full md:w-470"}/>
              <button className={"self-center md:self-auto ml-0 md:ml-5"} onClick={onClickSearchButton}>
                <img alt="" src={require('../../assets/images/btn_search.png')}/>
              </button>
            </div>
            <div className={"flex items-center mt-10 mb-2"}>
              <span className={"text-base font-bold"} style={{width: 100}}>検索結果</span>
              {/*<span className={"text-sm "}>{state.total}件中 1-{state.schoolList.length}件を表示</span>*/}
            </div>
            <div className={"bg-yellow h-px"}/>
            <div className={"px-4 pt-4"}>
              {
                state.schoolList.map((school, key) =>
                  <div key={key} className={"pt-2 cursor-pointer hover-item"}
                       onClick={(e) => props.onSelectSchool(e, school)}>
                    <span>{school.school_name}</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}
