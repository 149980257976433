import React, {Fragment, useRef} from 'react';
import {Col, Form, Row} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {useAuthState} from "../../context";
import {PageConstant} from "../../constants/PageConstant";
import {FormInput} from "../../components/form/FormInput";
import {editTeacher} from "../../api/axiosAPIs";
import _ from "lodash";
import {FormTextArea} from "../../components/form/FormTextArea";


function TeacherProfileMainInfo(props) {
  let history = useHistory();
  const formRef = useRef();
  const {loading, profile} = useAuthState();

  const items = [
    {to: PageConstant.TEACHER_PROFILE, label: '教員情報変更'},
    {to: '', label: '基本情報変更'},
  ]

  const onFinish = async (data) => {
    let response = await editTeacher(profile.teacher.id, data)
    if (!_.isEmpty(response.data)) {
      history.push(PageConstant.TEACHER_PROFILE, {data: data})
    }
  }

  return (
    <Fragment>
      <div className="pb-12 bg-blue-light">
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"rounded-xl bg-white overflow-hidden"}>
            <div className={"flex items-center justify-center py-4 bg-profile-yellow"}>
              <span className={"ml-4 text-black text-lg font-bold"}>教員情報変更</span>
            </div>
            <div className={"px-32 py-16"}>
              <Form
                ref={formRef}
                onFinish={onFinish}
                initialValues={profile.teacher}
              >
                {/*教員氏名*/}
                <FormInput
                  label={props.intl.formatMessage({id: 'form.item.teacher.name'})}
                  name="teacher_name"
                  placeholder={props.intl.formatMessage({id: 'form.item.teacher.name'})}
                  intl={props.intl}
                  required={true}
                  readOnly={false}
                />
                {/*担当科目*/}
                <FormTextArea
                  label={props.intl.formatMessage({id: 'form.item.teacher.subject'})}
                  name="subject_name"
                  placeholder={props.intl.formatMessage({id: 'form.item.teacher.subject'})}
                  intl={props.intl}
                  required={false}
                />
                {/*担当クラス*/}
                {/*<FormInput*/}
                {/*  label={props.intl.formatMessage({id: 'form.item.teacher.class'})}*/}
                {/*  name="class_name"*/}
                {/*  placeholder={props.intl.formatMessage({id: 'form.item.teacher.class'})}*/}
                {/*  intl={props.intl}*/}
                {/*  required={false}*/}
                {/*/>*/}
                <Row>
                  <Col span={8} offset={8}>
                    <button
                      type="submit"
                      className={"mt-4 flex items-center justify-center btn-primary-semi-round bg-blue"}
                      style={{width: 250, height: 55}}
                    >
                      <span className={"text-white text-base font-bold"}>変更する</span>
                    </button>
                  </Col>
                </Row>

              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default injectIntl(TeacherProfileMainInfo)