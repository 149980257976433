import React, {Fragment, useRef, useState} from 'react';
import {Spin, Upload} from "antd";
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useLocation} from "react-router-dom";
import {PageConstant} from "../../constants/PageConstant";
import styled from "styled-components";
import {UploadFinish} from "../../components/modal/UploadFinish";
import {BASE_URL, CERTIFICATE_PUBLISH} from "../../api/axiosConfig";
import {useAuthState} from "../../context";
import * as XLSX from 'xlsx';
import JSZip from "jszip";
import {Trim} from "../../helper/utils";

const {Dragger} = Upload;

const UploadContainer = styled.div`
  border: 2px dashed #3F75A0;
  padding: 30px;
  border-radius: 13px;
  text-align: center;
`

function CertificatePublish(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {loading, profile} = useAuthState();
  const [isProcessing, setIsProcessing] = useState(false);
  const data = useLocation().state.data
  const formRef = useRef();
  const _fileCount = useRef(0);
  const _fileList = useRef([]);
  const _pdfFileList = useRef([]);

  const items = [
    {to: PageConstant.PROGRAMS, label: 'プログラム管理'},
    {to: '', label: data.program_name},
    {to: '', label: '修了証発行'},
  ]

  const dragProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    openFileDialogOnClick: false,
    disabled: isProcessing,
    maxCount: 10,
    fileList: [],
    // action: BASE_URL + CERTIFICATE_PUBLISH,
    onChange(info) {
      openExcelFile(info.file.originFileObj)
    },
    onDrop(e) {
      setIsProcessing(true)
      _fileList.current = []
      _fileCount.current = e.dataTransfer.files.length
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const publishOneCertificate = (name, programName, schoolName) => {
    const xmlHttp = new XMLHttpRequest();
    const url = BASE_URL + CERTIFICATE_PUBLISH + "?name=" + name + "&programName=" + programName + "&schoolName=" + schoolName;
    xmlHttp.open("GET", url);
    xmlHttp.responseType = "blob";
    xmlHttp.setRequestHeader("Content-type", "application/pdf");
    return new Promise(function (resolve, reject) {
      xmlHttp.onreadystatechange = function () {
        if (this.readyState === 4 && xmlHttp.status === 200) {
          _pdfFileList.current.push(xmlHttp.response)
          resolve(xmlHttp.response);
        }
      };
      xmlHttp.send();
    });
  }

  const zipCertificates = async () => {
    const zip = new JSZip();
    for (let i = 0; i < _pdfFileList.current.length; i++) {
      zip.file("修了証" + (i + 1) + ".pdf", _pdfFileList.current[i]);
    }

    const blob = await zip.generateAsync({type: "blob"})
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "修了証.zip");
    document.body.appendChild(link);
    link.click();
    _pdfFileList.current = []
  }

  const createCertificate = async () => {
    const length = _fileList.current.length < 10 ? _fileList.current.length : 10
    const schoolName = profile ? profile.school_name : ''
    const programName = data ? data.program_name: '';
    for (let i = 0; i < length; i++) {
      const names = _fileList.current[i].split("\n")
      for (let j = 0; j < names.length; j++) {
        await publishOneCertificate(names[j], programName, schoolName)
      }
    }
    await zipCertificates()
    setIsProcessing(false)
    setIsModalVisible(true)
  }

  const processExcel = (data) => {
    const workbook = XLSX.read(data, {type: 'binary'});
    const firstSheet = workbook.SheetNames[0];
    const excelRowCSV = XLSX.utils.sheet_to_csv(workbook.Sheets[firstSheet]);
    const excelRows = excelRowCSV.split(",")
    if (excelRows.length > 0) {
      const names = Object.assign([], _fileList.current)
      names.push(Trim(excelRows[0]))
      _fileList.current = names
      if (_fileCount.current === _fileList.current.length) {
        createCertificate().catch(console.error)
      }
    }
  }

  const openExcelFile = (file) => {
    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          processExcel(reader.result);
        };
        reader.readAsBinaryString(file);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  }

  return (
    <Fragment>
      <div className={"px-32 pb-12 bg-yellow-light"}>
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"flex items-center justify-center py-8"}>
            <span className={"text-black text-3xl font-bold"}>{data.program_name}</span>
          </div>
          <div className={"rounded-xl bg-white overflow-hidden"}>
            <Spin spinning={isProcessing} size="large">
              <div className={"flex items-center justify-center py-4 bg-blue"}>
                <img alt={""} src={require('../../assets/images/flower_white.png')}/>
                <span className={"ml-4 text-white text-lg font-bold"}>修了証の発行</span>
              </div>
              <div className={"px-40"}>
                <div className={"pt-12 pb-6"}>
                  <span className={"text-black text-lg font-bold"}>
                    修了証を発行します。<br/>修了証を発行する氏名データ（Excelデータ）を以下にアップロードしてください。
                  </span>
                </div>
                <div className={"certificate py-4"}>
                  <Dragger ref={formRef} {...dragProps}>
                    <UploadContainer>
                      <span className={"text-xl text-light-blue font-bold"}>ここにファイルをドラッグ＆ドロップしてください</span>
                    </UploadContainer>
                  </Dragger>
                </div>
                <div className={"flex justify-center py-4"}>
                  <span
                    className={"text-xl font-bold under-padding-line-lg"}>アップロード完了後、修了証のPDFが一括ダウンロードされます。&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
                <div className={"py-8"}>
                  <span className={"text-black text-sm"}>
                    一度にアップロードできるファイルは10件までです。アップロードが完了するまではブラウザを閉じないでください。<br/><br/>
                    以下の形式のExcelファイルのみ正常に取り込まれます。Excelファイルのテンプレートもありますのでご利用ください。<br/>
                    ・１列目に氏名を入力してください（１列目のみ使用）<br/>
                    ・１シート目だけが取り込まれます
                  </span>
                </div>
              </div>

              <div className={"my-8 flex items-center justify-center"}>
                <a
                  className={"flex items-center justify-center bg-blue btn-primary-semi-round h-50px text-white text-sm"}
                  style={{width: 315}}
                  href={BASE_URL + 'template.xlsx'}
                >
                  <span>Excelファイルのテンプレート</span>
                </a>
              </div>
            </Spin>
          </div>
        </div>
      </div>
      <UploadFinish
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
      />
    </Fragment>
  )
}

export default injectIntl(CertificatePublish)