import React, {Fragment} from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid black;
  background-color: #EABD00;
  width: 100%;
`

export const ArrowItem = (props) => {
  return (
    <Fragment>
      <Container>
        <img alt={""} src={require('../../assets/images/arrow_right.png')} />
        <span className={"text-lg text-black ml-8"}>{props.title}</span>
      </Container>
    </Fragment>
  )
}
