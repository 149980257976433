import React from 'react';
import {Link} from 'react-router-dom';
import styled from "styled-components";
import {PageConstant} from "../../constants/PageConstant";

const LinkSpan = styled.span`
  font-size: 15px;
  color: white;
`
const CopyrightSpan = styled.span`
  font-size: 13px;
  color: white;
`

export const Footer = () => {
  return (
    <div className={"bg-yellow"}>
      <div className={"overflow-hidden"}>
        <img
          alt=""
          src={require("../../assets/images/footer_seperate.png")}
          className={"max-w-700p md:max-w-none h-2 md:h-auto"}
        />
      </div>
      <div className={"container"}>
        <div className="text-left md:text-right pt-8 leading-loose md:leading-relaxed">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.mietaplus.com/"
            className="mx-2 md:mx-10"
          >
            <LinkSpan>運営会社</LinkSpan>
          </a>
          <Link to={PageConstant.TERMS_OF_USE} className="mx-2 md:mx-10">
            <LinkSpan>利用規約</LinkSpan>
          </Link>
          <Link to={PageConstant.PRIVACY_POLICY} className="mx-2 md:mx-10">
            <LinkSpan>プライバシーポリシー</LinkSpan>
          </Link>
          <a href="/inquiry_form" className="mx-0 md:mx-0">
            <LinkSpan>お問い合わせ</LinkSpan>
          </a>
        </div>
        <div className="text-xs md:text-base md:text-right pt-2 pb-8">
          <CopyrightSpan>
            Copyright© MIETA, Inc. All Rights Reserved
          </CopyrightSpan>
        </div>
      </div>
    </div>
  );
}
