import React, {Fragment, useEffect, useState} from 'react';
import {injectIntl} from 'react-intl'
import {useHistory} from "react-router-dom";
import {useAuthState} from "../../context";
import {Bookmark} from "../../components/bookmark/Bookmark";
import {LessonItem} from "../../components/program/LessonItem";
import {Spin} from "antd";
import {SpanBr} from "../../components/common/SpanBr";
import {PageConstant} from "../../constants/PageConstant";
import {getSharedUrl} from "../../api/axiosAPIs";
import _ from "lodash";
import {GoogleSheet} from "../../components/common/GoogleSheet";

function SharedProgramDetail(props) {
  let history = useHistory();
  const {loading, profile} = useAuthState();
  const [programData, setProgramData] = useState({});
  const [isPublic, setIsPublic] = useState(true);

  const getParam = (key) => {
    return {
      key: key,
    }
  }

  const fetchSharedData = (key) => {
    getSharedUrl(getParam(key))
      .then(response => {
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            setProgramData(response.data.data)
          } else {
            setIsPublic(false)
          }
        }
      })
  }

  const onItemDetailClick = (e, item) => {
    e.preventDefault()
    history.push(PageConstant.SHARED_LESSON, {programTitle: programData.program_name, data: programData, item: item})
  }

  useEffect(() => {
    let key = props.location.pathname.split(PageConstant.SHARED_PROGRAM).slice(-1)[0].substring(1)
    fetchSharedData(key)
  }, []);

  return (
    <Fragment>
      <div className={"px-6 md:px-8 lg:px-32 pb-12 bg-yellow-light"}>
        {!isPublic && (
          <div
            className={"flex justify-center font-bold text-blue text-xl pt-16"}
          >
            アクセスできません。
          </div>
        )}
        {isPublic && (
          <Fragment>
            {programData.program_name && (
              <div className={"flex justify-center items-center py-8"}>
                <span className={"font-bold text-black text-3xl"}>
                  {programData.program_name}
                </span>
              </div>
            )}
            <div className={"rounded-xl bg-white py-16"}>
              <Bookmark title={"プログラム日程"} />
              {programData.schedule && (
                <div className={"px-6 md:px-8 lg:px-24 py-8"}>
                  <SpanBr name={programData.schedule} />
                </div>
              )}
              <Bookmark title={"開催場所"} />
              {programData.venue && (
                <div className={"px-6 md:px-8 lg:px-24 py-8"}>
                  <span>{programData.venue}</span>
                </div>
              )}
              <Bookmark title={"Todo・確認事項"} />
              {programData.confirm_item && (
                <div className={"px-6 md:px-8 lg:px-24 py-8"}>
                  <SpanBr name={programData.confirm_item} />
                </div>
              )}
              <Bookmark title={"流れについて"} />
              <div className={"px-6 md:px-8 lg:px-24 py-8"}>
                <GoogleSheet url={programData.flow_comment} />
              </div>
              <Bookmark title={"授業"} />
              {programData.lessons &&
                programData.lessons.map((item, key) => (
                  <Fragment key={key}>
                    <LessonItem
                      key={key}
                      item={item}
                      onItemDetailClick={onItemDetailClick}
                    />
                    {key < programData.lessons.length - 1 && (
                      <div className={"bg-gray-600 h-px mx-24 mt-8"} />
                    )}
                  </Fragment>
                ))}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default injectIntl(SharedProgramDetail)