import React, {Fragment} from 'react';
import {SpanBr} from "../common/SpanBr";


export const StudentMessage = (props) => {
  return (
    <Fragment>
      <div className={"relative rounded-xl bg-yellow-light pt-16"}>
        <div className={"absolute w-full left-0"} style={{top: -10}}>
          <div className={"flex justify-center"}>
            <img alt={""} src={require('../../assets/images/student_message_header.png')}/>
          </div>
        </div>
        <div className={"px-6 md:px-16"}>
          <SpanBr name={props.message}/>
        </div>
        <div className={"flex justify-end"}>
          <img alt={""} src={require('../../assets/images/symbol_message.png')}/>
        </div>
      </div>
    </Fragment>
  )
}
