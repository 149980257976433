import React, {Fragment} from 'react';
import {Top} from "../../components/home/Top";
import {Main} from "../../components/home/Main";
import {useAuthState} from "../../context";
import {isLogined} from "../../helper/utils";

export const Home = () => {
  const {profile} = useAuthState();

  return (
    <Fragment>
      <div className="App">
        {
          isLogined(profile) ? (
            <Main/>
          ) : (
            <Top/>
          )
        }
      </div>
    </Fragment>
  )
}