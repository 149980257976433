import React, {Fragment, useEffect, useState} from 'react';
import {Breadcrumb} from "../../components/breadcrumb/Breadcrumb";
import {injectIntl} from 'react-intl'
import {useAuthState} from "../../context";
import {Pagination} from "antd";
import {ProgramItem} from "../../components/program/ProgramItem";
import _ from "lodash";
import {getProgramList} from "../../api/axiosAPIs";


function Programs(props) {
  const {loading, profile} = useAuthState();
  const [state, setState] = useState({page: 1, rowsPerPage: 15, programList: [], total: 0});

  const items = [
    {to: '', label: 'プログラム管理'},
  ]

  const onChange = (page, rowsPerPage) => {
    fetchProgramList(page, rowsPerPage)
  }

  const fetchProgramList = (page, rowsPerPage) => {
    getProgramList(getParam(page, rowsPerPage))
      .then(response => {
        if (!_.isEmpty(response.data)) {
          if (response.data.data) {
            setState({
              page: page,
              rowsPerPage: rowsPerPage,
              programList: response.data.data,
              total: response.data.total,
            })
          }
        }
      })
  }

  const getParam = (page, rowsPerPage) => {
    const schoolId = profile.teacher ? profile.teacher.school_id : ''
    return {
      page: page,
      per_page: rowsPerPage,
      school_id: schoolId,
    }
  }

  useEffect(() => {
    fetchProgramList(state.page, state.rowsPerPage)
  }, []);

  return (
    <Fragment>
      <div className="px-32 pb-12 bg-yellow-light">
        <div className={"container"}>
          <Breadcrumb items={items}/>
          <div className={"flex justify-center pb-5"}>
            <img alt="" src={require('../../assets/images/mark_manage.svg')}/>
          </div>
          <div className={"rounded-xl bg-white overflow-hidden"}>
            <div className={"bg-blue py-5"}>
              <span className={"flex justify-center text-white font-bold text-4xxl"}>プログラム一覧</span>
            </div>
            <div className={"py-12 px-24"}>
              {
                state.programList.map((item, key) => (
                  <div key={key}>
                    <ProgramItem item={item}/>
                    {
                      (key < state.programList.length - 1) && (
                        <div className={"bg-blue h-px my-4"}/>
                      )
                    }
                  </div>
                ))
              }
            </div>
          </div>
          <div className={"py-12 flex items-center justify-center"}>
            <Pagination
              showSizeChanger={false}
              current={state.page}
              pageSize={state.rowsPerPage}
              total={state.total}
              onChange={onChange}
            />
          </div>
        </div>
      </div>      
    </Fragment>
  )
}

export default injectIntl(Programs)