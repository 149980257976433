import React, {Fragment} from 'react';
import styled from "styled-components";

const InstructorItemContainer = styled.div`
  border-left: 6px solid #1E425F;
  padding-left: 15px;
  margin-bottom: 25px;
`

export const LessonItem = (props) => {
  return (
    <Fragment>
      <div className={"relative mx-6 md:mx-8 lg:mx-24 pb-8"}>
        {
          props.locked && (
            <div className={"absolute w-full h-full overflow-hidden"}
                 style={{backgroundColor: 'rgba(26, 32, 44, 0.2)'}}></div>
          )
        }
        <div className={"py-8"}>
          <span className={"text-xl font-bold text-black"}>
            {props.item.lesson_name}
          </span>
        </div>
        <div className={"flex items-center flex-col lg:flex-row"}>
          <div style={{minWidth: 300, height: 250}}  className={"flex justify-start"}>
            <img alt={""} className={"w-full h-full"} src={props.item.lesson_image_urls[0]}/>
          </div>
          <div className={"flex mt-4 lg:mt-0 ml-0 lg:ml-6 w-full h-auto lg:h-250px"}>
            <div>
              {
                props.item.instructors.map((item, key) => (
                  <InstructorItemContainer key={key}>
                    <div className={"py-2 flex items-center"}>
                      <span className={"text-lg font-bold text-black"}>講師：{item.instructor_name}</span>
                      <span className={"text-sm text-yellow ml-12"}>{item.instructor_eng_name}</span>
                    </div>
                    <div>
                      <span className={"text-base text-gray-700"}>{item.venue}</span>
                    </div>
                  </InstructorItemContainer>
                ))
              }
              {
                props.teaser && (
                  <div className={"flex justify-center py-1"} style={{width: 190}}>
                    <img alt="" src={require('../../assets/images/click.png')}/>
                  </div>
                )
              }
              <div>
                <button
                  className={"flex items-center justify-center bg-yellow btn-secondary-round text-white font-bold text-sm h-40px"}
                  style={{width: 190}}
                  onClick={(e) => props.onItemDetailClick(e, props.item)}
                >
                  <span className={"text-base font-bold"}>詳細</span>
                </button>
              </div>
              {
                props.teaser && (
                  <div className={"py-2"}>
                    <span className={"text-base font-bold text-blue"}>※サンプルの授業内容が見られます。</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
