import React from 'react';
import {PageConstant} from "../constants/PageConstant";
import {Login, Logout} from '../pages/login';
import {Signup, SignupForm, SignupFormConfirm, SignupVirtualRegister} from '../pages/signup';
import {ForgetPassword, ForgetPasswordEmailSend, ResetPassword, ResetPasswordFinish} from '../pages/forgetPassword';
import {Home} from '../pages/home';
import {InquiryForm, InquiryFormConfirm, InquiryFormSendFinish} from '../pages/inquiry';
import {ColumnDetail, Columns} from '../pages/column';
import {ThemeDetail, ThemeInquirySendFinish, Themes} from '../pages/theme';
import {CertificatePublish, ProgramDetail, Programs, ProgramGuide} from '../pages/program';
import {LessonDetail} from '../pages/lesson';
import {TeaserProgram, TeaserLesson} from '../pages/teaser';
import {InstructorDetail} from '../pages/instructor';
import {
  TeacherManage,
  TeacherManageAdd,
  TeacherManageAddSend,
  TeacherManageEdit,
  TeacherProfile,
  TeacherProfileEmail,
  TeacherProfileEmailSend,
  TeacherProfileMainInfo,
  TeacherProfilePassword
} from '../pages/teacher';
import {SharedProgramDetail, SharedLessonDetail} from "../pages/shared";
import {Error404, TermsOfUse, PrivacyPolicy} from '../pages/common';

const routes = [
  {path: PageConstant.HOME, component: Home, isPrivate: false},
  {path: PageConstant.LOGIN, component: Login, isPrivate: false},
  {path: PageConstant.LOGOUT, component: Logout, isPrivate: false},
  {path: PageConstant.SIGNUP, component: Signup, isPrivate: false},
  {path: PageConstant.TERMS_OF_USE, component: TermsOfUse, isPrivate: false},
  {path: PageConstant.PRIVACY_POLICY, component: PrivacyPolicy, isPrivate: false},
  {path: PageConstant.SIGNUP_VIRTUAL_REGISTER, component: SignupVirtualRegister, isPrivate: false},
  {path: PageConstant.SIGNUP_FORM, component: SignupForm, isPrivate: false},
  {path: PageConstant.SIGNUP_FORM_CONFIRM, component: SignupFormConfirm, isPrivate: false},
  {path: PageConstant.FORGET_PASSWORD, component: ForgetPassword, isPrivate: false},
  {path: PageConstant.FORGET_PASSWORD_EMAIL_SEND, component: ForgetPasswordEmailSend, isPrivate: false},
  {path: PageConstant.RESET_PASSWORD, component: ResetPassword, isPrivate: false},
  {path: PageConstant.RESET_PASSWORD_FINISH, component: ResetPasswordFinish, isPrivate: false},
  {path: PageConstant.INQUIRY_FORM, component: InquiryForm, isPrivate: false},
  {path: PageConstant.INQUIRY_FORM_CONFIRM, component: InquiryFormConfirm, isPrivate: false},
  {path: PageConstant.INQUIRY_FORM_SEND_FINISH, component: InquiryFormSendFinish, isPrivate: false},
  {path: PageConstant.SHARED_PROGRAM, component: SharedProgramDetail, isPrivate: false},
  {path: PageConstant.SHARED_LESSON, component: SharedLessonDetail, isPrivate: false},
  {path: PageConstant.COLUMNS, component: Columns, isPrivate: true},
  {path: PageConstant.COLUMN_DETAIL, component: ColumnDetail, isPrivate: true},
  {path: PageConstant.THEMES, component: Themes, isPrivate: true},
  {path: PageConstant.THEME_DETAIL, component: ThemeDetail, isPrivate: true},
  {path: PageConstant.PROGRAMS, component: Programs, isPrivate: true},
  {path: PageConstant.PROGRAM_GUIDE, component: ProgramGuide, isPrivate: true},
  {path: PageConstant.PROGRAM_DETAIL, component: ProgramDetail, isPrivate: true},
  {path: PageConstant.CERTIFICATE_PUBLISH, component: CertificatePublish, isPrivate: true},
  {path: PageConstant.LESSON_DETAIL, component: LessonDetail, isPrivate: true},
  {path: PageConstant.TEASER_PROGRAM, component: TeaserProgram, isPrivate: true},
  {path: PageConstant.TEASER_LESSON, component: TeaserLesson, isPrivate: true},
  {path: PageConstant.THEME_INQUIRY_SEND_FINISH, component: ThemeInquirySendFinish, isPrivate: true},
  {path: PageConstant.TEACHER_PROFILE, component: TeacherProfile, isPrivate: true},
  {path: PageConstant.TEACHER_PROFILE_MAIN_INFO, component: TeacherProfileMainInfo, isPrivate: true},
  {path: PageConstant.TEACHER_PROFILE_EMAIL, component: TeacherProfileEmail, isPrivate: true},
  {path: PageConstant.TEACHER_PROFILE_EMAIL_SEND, component: TeacherProfileEmailSend, isPrivate: true},
  {path: PageConstant.TEACHER_PROFILE_PASSWORD, component: TeacherProfilePassword, isPrivate: true},
  {path: PageConstant.TEACHER_MANAGE, component: TeacherManage, isPrivate: true},
  {path: PageConstant.TEACHER_MANAGE_ADD, component: TeacherManageAdd, isPrivate: true},
  {path: PageConstant.TEACHER_MANAGE_ADD_SEND, component: TeacherManageAddSend, isPrivate: true},
  {path: PageConstant.TEACHER_MANAGE_EDIT, component: TeacherManageEdit, isPrivate: true},
  {path: PageConstant.INSTRUCTOR_DETAIL, component: InstructorDetail, isPrivate: true},

  {path: PageConstant._404, component: Error404, isPrivate: false},
];

export default routes;
